import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { Grid, MenuItem, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import classes from "../../../assets/styles/Task.module.css";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import Message from '../../../sharedComponents/ui/Message';
import moment from "moment";
import 'moment/locale/de';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import Switch, { SwitchProps } from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAppSelector } from '../../../app/hooks';
import { selectUserType } from '../../../app/slices/userDataSlice';
import 'moment/locale/en-gb';
import { useIntl } from 'react-intl';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export interface DialogProps {
  open: boolean;
  setOpen: CallableFunction;
  loading: any,
  callStatus: any,
  errorMsg: any,
  confirmAction: any,
  clientsList: any;
  typesList: any;
  moduleList: any;
  search: any;
  setSearch: any;
  currentSearch: any;
  selectedClientId: string[];
  selectedTypeId: any;
  selectedModule: number[];
  searchTypesList: any;
  selectedSearchTypeId: any;
  clickedModule: number[];
  setClickedModule: React.Dispatch<React.SetStateAction<number[]>>;
  clickedClient: string[];
  setClickedClient: React.Dispatch<React.SetStateAction<string[]>>;
  clickedType: number[];
  setClickedType: React.Dispatch<React.SetStateAction<number[]>>;
  isFiltering: any;
  setIsFiltering: any;
}
interface FieldsType {
  title: string;
  typeId: number[] | null;
  module: number[] | null;
  startDate: string | null;
  endDate: string | null;
  clientId: string[] | null;
  searchType: number;
  search : string;
}

const FilterTask = (props: DialogProps) => {

  const { open, setOpen, loading, callStatus, errorMsg, confirmAction, clientsList, typesList, moduleList, search, setSearch, currentSearch, selectedClientId, selectedTypeId, selectedModule, clickedModule, setClickedModule, clickedClient, setClickedClient, searchTypesList, selectedSearchTypeId, clickedType, setClickedType, isFiltering, setIsFiltering } = props;

  const [activeButton, setActiveButton] = useState<string>('Tasks');

  //Fields
  const [fileType, setFileType] = useState('');

  const [startError, setStartError] = useState(false);
  const [endError, setEndError] = useState(false);

  const intl = useIntl();
  const [taskObject, setTaskObject] = useState<FieldsType>({
    title: "",
    typeId: selectedTypeId || 0,
    module: selectedModule || 0,
    startDate: null,
    endDate: null,
    clientId: localStorage.getItem('userType') === '2' && clientsList?.length > 0 ? clientsList[0].id : selectedClientId ? selectedClientId : null,
    searchType: selectedSearchTypeId || 1,
    search: search
  });

  const handleOpen = () => {
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => {
    // Updating state with an empty object to trigger a re-render
    updateState({});
  }, []);

  const handleStartDateChange = (newValue: any) => {
    if (newValue && moment(newValue).isValid() && moment(newValue).isSameOrAfter(moment(), 'day') && (!taskObject.endDate || moment(newValue).isSameOrBefore(moment(taskObject.endDate, 'YYYY-MM-DD'), 'day'))) {
      setTaskObject(prevState => ({
        ...prevState,
        startDate: newValue ? newValue.format('YYYY-MM-DD') : null,
      }));
      setStartError(false)
    }
    else {
      setStartError(true);
    }
  };


  const handleEndDateChange = (newValue: any) => {
    if (newValue && moment(newValue).isValid() && moment(newValue).isSameOrAfter(moment(), 'day') && (!taskObject.startDate || moment(newValue).isSameOrAfter(moment(taskObject.startDate, 'YYYY-MM-DD'), 'day'))) {
      setTaskObject(prevState => ({
        ...prevState,
        endDate: newValue ? newValue.format('YYYY-MM-DD') : null,
      }));
      setEndError(false);
    }
    else {
      setEndError(true);
    }
  };

  const handleFilter = () => {

    const filterPayload = {
      ...taskObject,
      StartDate: taskObject.startDate,
      EndDate: taskObject.endDate,
      typeId: taskObject.typeId || null,
      // fileType: activeButton === 'Files' ? fileType : null,
      search: search,
      clientId: taskObject.clientId || null,
      module: taskObject.module || null,
      searchType: taskObject.searchType,
    };
    confirmAction(filterPayload);
    handleClose();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (open) {
      setSearch(currentSearch || '');
      handleOpen();
    }
    else {
      setOpen(false);
      // setTaskObject({
      //   title: "",
      //   startDate: null,
      //   endDate: null,
      //   typeId: null,
      //   module: null,
      //   clientId: localStorage.getItem('userType') === '1' ? "" : null,
      //   searchType: null
      // });
    }
  }, [open, currentSearch]);

  useEffect(() => {
    if (selectedClientId !== undefined) {
      setTaskObject(prev => ({
        ...prev,
        clientId: selectedClientId || ''
      }));
    }
    if (selectedTypeId !== undefined) {
      setTaskObject(prev => ({
        ...prev,
        typeId: selectedTypeId || ''
      }));
    }
    if (selectedModule !== undefined) {
      setTaskObject(prev => ({
        ...prev,
        module: selectedModule || ''
      }))
    }
  }, [selectedClientId, selectedTypeId, selectedModule]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{ sx: { borderRadius: "8px", width: "656px" } }}
    >
      <Grid item container padding={2.5} rowGap={2} columnSpacing={2} >
        <Grid item container className='neutral-4' fontWeight={400} alignItems='center' justifyContent='space-between'>
          <Message id="task.filter" className='Field-input font-weight-400 font-14' />
          <span style={{ cursor: 'pointer' }} onClick={() => handleClose()}><CloseIcon /></span>
          <Grid item className='greyLine' width='100%' xs={12}></Grid>

        </Grid>
        <Grid item container direction='column' alignItems='center' marginTop='10px'>
          <Grid item className='neutral-9 font-weight-400'>Typ</Grid>
        </Grid>

        <Grid item container direction='row' marginTop='15px' columnGap='7px'>
          <Grid item xs={3.9} className='neutral-9 font-weight-400'>

            <button className={activeButton === 'Tasks' ? 'blueButton' : 'greyButton'} onClick={() => {
              setActiveButton('Tasks');
              setFileType('');
              setTaskObject(prev => ({
                ...prev,
                module: null,
                searchType: 1,
                typeId: [1]
              }))
            }} style={{ width: '100%', height: '48px' }}><Message id="task.tasks" className='Field-input font-weight-700 labelsLinks' /></button>
          </Grid>
          <Grid item xs={3.9} className='neutral-9 font-weight-400'>
            <button className={activeButton === 'Communication' ? 'blueButton' : 'greyButton'} onClick={() => {
              setActiveButton('Communication')
              setTaskObject(prev => ({
                ...prev,
                module: null,
                searchType: 3,
                typeId: [3]
              }))
            }} style={{ width: '100%', height: '48px' }}><Message id="task.messages" className='Field-input font-weight-700 labelsLinks' /></button>
          </Grid>
          <Grid item xs={3.9} className='neutral-9 font-weight-400'>
            <button className={activeButton === 'Files' ? 'blueButton' : 'greyButton'} onClick={() => {
              setActiveButton('Files')
              setTaskObject(prev => ({
                ...prev,
                typeId: [2],
                module: null,
                searchType: 2,
              }))
            }} style={{ width: '100%', height: '48px' }}><Message id="documents" className='Field-input font-weight-700 labelsLinks' /></button>
          </Grid>
        </Grid>

        {/* <Grid item container spacing={2} marginTop={activeButton === 'Communication' ? '2px' : '15px'}> */}
        {/* {activeButton === 'Communication' && (
        <Grid item xs={12}>Full Text Search</Grid>
         )}    */}
        {activeButton === 'Communication' ?
          <Grid item className='font-14' >
            <Message id="text.full-task-search" className='Field-input font-weight-400 font-14' /> </Grid> : ""}

        <Grid item xs={activeButton === 'Communication' ? 12 : 6}>
          {/* <Grid item xs={6}> */}
          <TextField
            fullWidth
            value={search}
            label={activeButton === 'Communication' ? intl.formatMessage({ id: 'text.search-in-communication' }) : activeButton === 'Tasks' ? intl.formatMessage({ id: 'text.search-in-tasks' }) : intl.formatMessage({ id: 'text.search-in-files' })}
            variant="outlined"
            sx={{
              '& label.Mui-focused': {
                color: '#C3C2C7',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#B2BAC2',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#C3C2C7',
                },
                '&:hover fieldset': {
                  borderColor: '#B2BAC2',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#6F7E8C',
                },
              },
            }}
            InputProps={{
              style: {
                borderRadius: '12px',
                fontFamily: 'DM Sans',
                height: '50px',
                borderTopLeftRadius: '10px',
                backgroundColor: '#F7F9FF',
              },
            }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            fullWidth
            value={taskObject.module}
            label={intl.formatMessage({ id: 'task.module' })}
            variant="outlined"
            sx={{
              '& label.Mui-focused': {
                color: '#C3C2C7',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#B2BAC2',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#C3C2C7',
                },
                '&:hover fieldset': {
                  borderColor: '#B2BAC2',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#6F7E8C',
                },
              },
            }}
            InputLabelProps={{
              shrink: Boolean((taskObject.module ?? []).length > 0)
            }}
            InputProps={{
              style: {
                borderRadius: '12px',
                fontFamily: 'DM Sans',
                height: '50px',
                borderTopLeftRadius: '10px',
                backgroundColor: '#F7F9FF',
              },
            }}
            // onChange={(e) => {
            //   setTaskObject(prev => ({
            //     ...prev,
            //     module: parseInt(e.target.value)
            //   }))
            //   forceUpdate();
            // }}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              setTaskObject((prev: any) => ({
                ...prev,
                module: [value],
              }));
              setClickedModule((prev) => [...prev, value]);
              forceUpdate();
            }}
          >
            {moduleList?.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* <Grid item container spacing={2}> */}
        {/* {activeButton !== 'Communication' && (
          <Grid item xs={6}>
            <TextField
              select
              fullWidth
              value={activeButton === 'Tasks' ? taskObject.typeId : fileType}
              label={activeButton === 'Tasks' ? intl.formatMessage({ id: 'task.task-type' }) : intl.formatMessage({ id: 'task.file-type' })}
              variant="outlined"
              sx={{
                '& label.Mui-focused': {
                  color: '#C3C2C7',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#B2BAC2',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#C3C2C7',
                  },
                  '&:hover fieldset': {
                    borderColor: '#B2BAC2',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#6F7E8C',
                  },
                },
              }}
              InputLabelProps={{
                // shrink: activeButton === 'Tasks' ? Boolean(taskObject.typeId) : Boolean(fileType)
                shrink: activeButton === 'Tasks' ? Boolean((taskObject.typeId ?? []).length > 0) : Boolean(fileType)
              }}
              InputProps={{
                style: {
                  borderRadius: '12px',
                  fontFamily: 'DM Sans',
                  height: '50px',
                  borderTopLeftRadius: '10px',
                  backgroundColor: '#F7F9FF',
                },
              }}
              onChange={(e) => {
                const selectedValue = parseInt(e.target.value);
                if (activeButton === 'Tasks') {
                  setTaskObject((prev: any) => ({
                    ...prev,
                    typeId: [selectedValue],
                  }));
                  forceUpdate();
                }
                // else {
                //   setFileType(selectedValue);
                // }
                setClickedType((prev) => [...prev, selectedValue]);
              }}
            >
              {activeButton === 'Tasks'
                ?
                typesList?.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))
                : [
                  <MenuItem key="pdf" value="pdf">PDF</MenuItem>,
                  <MenuItem key="doc" value="doc">DOC</MenuItem>,
                  <MenuItem key="jpg" value="jpg">JPG</MenuItem>
                ]
              }
            </TextField>
          </Grid>
        )} */}

        {/* {localStorage.getItem('userType') === '1' ? */}
        <Grid item xs={6}>
          {localStorage.getItem('userType') === '2' ?
            <Grid item className='textfield' paddingLeft={2}>{localStorage.getItem("username")}</Grid>
            :
            <TextField
              select
              fullWidth
              disabled={localStorage.getItem('userType') === '2'}
              // value={localStorage.getItem('userType') === '2'  && clientsList?.length>0 ?clientsList[0].id:taskObject.clientId}
              value={taskObject.clientId}
              label={localStorage.getItem('userType') === '1' ? intl.formatMessage({ id: 'task.client' }) : ''}
              variant="outlined"
              sx={{
                '& label.Mui-focused': {
                  color: '#C3C2C7',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#B2BAC2',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#C3C2C7',
                  },
                  '&:hover fieldset': {
                    borderColor: '#B2BAC2',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#6F7E8C',
                  },
                },
              }}
              InputProps={{
                style: {
                  borderRadius: '12px',
                  fontFamily: 'DM Sans',
                  height: '50px',
                  borderTopLeftRadius: '10px',
                  backgroundColor: '#F7F9FF',
                },
              }}
              // onChange={(e) => {
              //   setTaskObject(prevState => ({
              //     ...prevState,
              //     clientId: String(e.target.value),
              //   }));
              // }}
              onChange={(e) => {
                const updatedState = String(e.target.value)
                setTaskObject(prevState => ({
                  ...prevState,
                  clientId: [updatedState]
                }))
                setClickedClient((prev) => [...prev, updatedState]);
              }}
            >
              {clientsList?.map((option: any) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>}

        </Grid>
        {/* :
            <></>} */}
        {/* </Grid> */}
        {/* 
        </Grid> */}


        <Grid item container direction='row' spacing={2} marginTop='15px'>
          <Grid item xs={12}><Message id="task.date-range" className='Field-input font-weight-400 font-14' /></Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-gb"}>
              <Stack spacing={3}>
                <DatePicker
                  label={intl.formatMessage({ id: 'task.from' })}
                  value={taskObject.startDate ? moment(taskObject.startDate, 'YYYY-MM-DD') : null}
                  onChange={handleStartDateChange}
                  maxDate={taskObject.endDate ? moment(taskObject.endDate, 'YYYY-MM-DD') : undefined}
                  slotProps={{
                    textField: {
                      error: startError,
                      sx: {
                        '& label.Mui-focused': {
                          color: '#C3C2C7',
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#B2BAC2',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#C3C2C7',
                          },
                          '&:hover fieldset': {
                            borderColor: '#B2BAC2',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#6F7E8C',
                          },
                        },
                      },
                      InputProps: {
                        className: "fieldDesign",
                        style: {
                          borderRadius: '12px',
                          fontFamily: 'DM Sans',
                          height: '50px',
                          borderTopLeftRadius: '10px',
                          backgroundColor: '#F7F9FF',
                        },
                      },
                    },
                  }}
                  format="DD.MM.YYYY"
                />

              </Stack>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-gb"}>
              <Stack spacing={3}>
                <DatePicker
                  label={intl.formatMessage({ id: 'task.to' })}
                  value={taskObject.endDate ? moment(taskObject.endDate, 'YYYY-MM-DD') : null}
                  onChange={handleEndDateChange}
                  minDate={taskObject.startDate ? moment(taskObject.startDate, 'YYYY-MM-DD') : undefined}
                  slotProps={{
                    textField: {
                      error: endError,
                      sx: {
                        '& label.Mui-focused': {
                          color: '#C3C2C7',
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#B2BAC2',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#C3C2C7',
                          },
                          '&:hover fieldset': {
                            borderColor: '#B2BAC2',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#6F7E8C',
                          },
                        },
                      },
                      InputProps: {
                        className: "fieldDesign",
                        style: {
                          borderRadius: '12px',
                          fontFamily: 'DM Sans',
                          height: '50px',
                          borderTopLeftRadius: '10px',
                          backgroundColor: '#F7F9FF',
                        },
                      },
                    },
                  }}
                  format="DD.MM.YYYY"
                />

              </Stack>
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid item container justifyContent="flex-end">
          <button className='blueButton' disabled={endError || startError} style={{ width: '120px' }}
            onClick={handleFilter}
          >
            <Message id="task.filter" className='Field-input font-weight-700 labelsLinks' />
          </button>
        </Grid>



        {errorMsg ?
          <Grid item textAlign='center' className='errorText' paddingTop={1}>{errorMsg}</Grid>
          :
          <></>}
      </Grid>
    </Dialog>
  )
}

export default FilterTask