import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { Box, Grid, ClickAwayListener } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckedIcon from '../../../../assets/images/icons/CheckedIcon.png';
import UnCheckedIcon from '../../../../assets/images/icons/UncheckedIcon.png';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import classes from "../../../../assets/styles/User.module.css";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import Message from '../../../../sharedComponents/ui/Message';

const Transition = React.forwardRef(function Transition(
props: TransitionProps & {
children: React.ReactElement<any, any>;
},
ref: React.Ref<unknown>,
) {
return <Slide direction="down" ref={ref} {...props} />;
});

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
[`& .${tooltipClasses.tooltip}`]: {
boxShadow: '0px 2px 4px 0px #0000001F',
backgroundColor: '#FEFEFF',
borderRadius: '10px',
padding: 0,
color: 'black',
},
}));

export interface DialogProps {
openUpdateRoleDialog: boolean;
setOpenUpdateRoleDialog: CallableFunction;
user: any;
setUser: any;
roles: any;
confirmAction: any;
loading: boolean;
callStatus: any;
errorMsg: any
}

const UpdateRole = (props: DialogProps) => {

const { openUpdateRoleDialog, setOpenUpdateRoleDialog, user, setUser, roles, confirmAction, loading, callStatus, errorMsg } = props;

const [open, setOpen] = useState(false);
const [isHovered, setIsHovered]= useState(false);

const [isRolesOpen, setIsRolesOpen]= useState(false);

const filteredRoles = localStorage.getItem('userType') === '1'?roles?.filter((r: { typeId: number; }) => r.typeId === user.type):localStorage.getItem('userType') === '2'?
roles?.filter((r: { typeId: number; }) => r.typeId === 2):null;

const [, updateState] = React.useState({});
// Create a memoized callback to force re-render
const forceUpdate = React.useCallback(() => {
// Updating state with an empty object to trigger a re-render
updateState({});
}, []);

const handleClickOpen = () => {
setOpenUpdateRoleDialog(true);
setOpen(true)
};

const handleClose = () => {
setOpen(false);
setUser();
setOpenUpdateRoleDialog(false);
};

useEffect(() => {
if (openUpdateRoleDialog) {
handleClickOpen();
}
else {
setOpen(false);
}
}, [openUpdateRoleDialog]);

const handleRemoveRole = (roleId: any) => {
    const updatedRoles = user.roles.filter((r: any) => r !== roleId);
    setUser((prevUser: any) => ({ ...prevUser, roles: updatedRoles }));
    forceUpdate(); 
};
console.log('user',user)

return (
<Dialog
open={open}
TransitionComponent={Transition}
keepMounted
onClose={handleClose}
aria-describedby="alert-dialog-slide-description"
PaperProps={{ sx: { borderRadius: "15px", width: '60%' } }}

>
<Grid item container direction='column' padding={3} rowGap={1}>
    <Grid item container className='black labels bold' fontWeight={400} alignItems='center' justifyContent='space-between'>
    <Message id="ta.edit.change-roles" className="Field-input labelsLinks font-weight-400" />
        <span style={{ cursor: 'pointer' }} onClick={() => handleClose()}><CloseIcon /></span>
    </Grid>
    <Grid item className='greyLine' width='100%'></Grid>
    <Grid item className='neutral-9 desktop-header-5 font-weight-400' paddingTop={1.5}>
    <Message id="ta.edit.edit-roles" className="Field-input desktop-header-5 font-weight-400" />
    </Grid>
    <Grid item container direction='row' spacing={1}>
        <Grid item container xs={6} direction='column'>
            <Grid item><Message id="login.email" className="Field-input labelsLinks font-weight-400"/></Grid>
            <Grid item className='textfield'> {user?.email}</Grid>
        </Grid>
        <Grid item container xs={6} direction='column'>
            <Grid item><Message id="ta.edit.roles" className="Field-input labelsLinks font-weight-400"/></Grid>
            <Grid item container columnGap={1} rowGap={1} alignItems='center' className='textfield' style={{ height: '50px' }}>
                {user.roles.map((role: any) => (
                    <Grid item key={role} className={classes['roleBox']}>
                 
                    {roles?.find((r: { id: any; }) => r.id === role)?.name}
                    <span style={{ cursor: 'pointer', marginLeft: '8px',marginTop:'5px' }} onClick={() => handleRemoveRole(role)}>
                        <CloseIcon fontSize='small'/>
                    </span>
                
                </Grid>
                ))}
                 <ClickAwayListener onClickAway={(event) => {
                                const target = event.target as HTMLElement;
                                if (
                                  isRolesOpen
                                ) {
                                  setIsRolesOpen(false);
                                }
                              }}>
                <Grid item>
                    <HtmlTooltip
                     open={isRolesOpen}
                     onClose={() => setIsRolesOpen(false)}
                     disableHoverListener
                        title={
                            <React.Fragment>
                                <Grid item container rowGap={0.5} maxHeight='228px' padding={1} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto', width:'250px' }}>
                                    {filteredRoles?.map((role: any, index: number) => (
                                        <Grid item container alignItems='center' key={role.id} columnGap={1} 
                                        // className={classes[user?.roles?.includes(role.id) ? 'filter-selected' : 'filter-not-selected']}
                                         justifyContent='flex-start'
                                         style={{
                                            flexWrap: 'nowrap',
                                            width: '100%',
                                            fontSize:'14px',
                                            marginBottom:'18px'
                                          }}
                                            onMouseOver={(e) => {
                                                const target = e.target as HTMLElement;
                                                target.style.cursor = 'pointer';
                                            }}
                                            onClick={(e) => {
                                                if (!user?.roles?.includes(role.id)) {
                                                    const updatedRoles = [...user.roles, role.id];
                                                    user.roles = updatedRoles;
                                                    forceUpdate();
                                                    // setSelectedRoles((prevTenants: any) => [...prevTenants, val.id]);
                                                }
                                                else {
                                                    const updatedRoles = user?.roles?.filter((r: { id: any; }) => r !== role.id)
                                                    user.roles = updatedRoles;
                                                    forceUpdate();
                                                }
                                            }}
                                        >
                                            <Grid className='bordered-box' bgcolor={user?.roles?.includes(role.id) ? '#CCF0EB' : '#E0E0E0'}>
                                                {
                                                    user.roles.includes(role.id) ?
                                                    <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                                    :
                                                  <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                                }
                                                {/* <CheckIcon fontSize='small' htmlColor={user?.roles?.includes(role.id) ? '#00B69B' : '#9E9E9E'} /> */}
                                                </Grid>
                                            <Grid item>{role.name}</Grid>
                                        </Grid>
                                    ))}

                                </Grid>
                            </React.Fragment>
                        }
                    >
                        <ControlPointIcon onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} htmlColor={isHovered ? '#93919A' : isRolesOpen ? '#5D5FEF' : '#C3C2C7'} style={{ cursor: 'pointer' }} onClick={()=>{ setIsRolesOpen((prev:any)=>!prev);}}/>

                    </HtmlTooltip >
                </Grid>
                </ClickAwayListener>

            </Grid>
        </Grid>
    </Grid>
    <Grid item container direction='row' justifyContent='flex-end' marginTop={3} marginBottom={2} columnGap={1.5}>
        {errorMsg ?
            <Grid item textAlign='center' className='errorText' paddingTop={1}>{errorMsg}</Grid>
            :
            <></>
        }
        <Grid item xs={3} >
            <button className='greyButton' onClick={() => handleClose()}><Message id="button.cancel" className="Field-input labelsLinks labels-extra-bold"/></button>
        </Grid>
        <Grid item xs={3}>
            <button
                className={callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton'}
                        disabled={loading || user.roles.length === 0 || callStatus === 'Pass' || callStatus === 'Fail'}
                onClick={() => confirmAction()}>
                {loading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                    : callStatus === 'Pass' ? <CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} />
                        : callStatus === 'Fail' ? "Failed"
                            : <Message id="button.apply" className="Field-input labelsLinks labels-extra-bold"/>}
            </button>
        </Grid>
    </Grid>

</Grid>
</Dialog>
)
}

export default UpdateRole