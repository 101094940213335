
export const femaleId = 1;
export const maleId = 2;
export const diverseId = 3;

export const salutations = [ 
    {
        id: femaleId,
        selection: "Weiblich",
        salutation: "Sehr geehrte Frau"
    },
    {
        id: maleId,
        selection: "Männlich",
        salutation: "Sehr geehrter Herr"
    },
    {
        id: diverseId,
        selection: "Divers",
        salutation: "Guten Tag "
    },
]