import React, { useState, useCallback, useRef, useEffect } from "react";
import { atom, RecoilRoot, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Grid,
  ClickAwayListener,
  Fade,
  List,
  ListItemButton,
  Paper,
  Box,
  TextField,
  CssBaseline,
  CircularProgress
} from "@mui/material";
import Message from '../../../sharedComponents/ui/Message';
import ProfileImage from "../../../sharedComponents/ui/ProfileImage";
import * as userServices from "../../../services/user-services.proxy";
import { useAppSelector } from "../../../app/hooks";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import classes from "../../../assets/styles/Task.module.css";
import CheckIcon from '@mui/icons-material/Check';
import InputAdornment from '@mui/material/InputAdornment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachmentIcon from "../../../assets/images/icons/attachmentIcon.png";
import * as StatusesConsts from "../../../status-consts";
import { useIntl } from 'react-intl';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import downloadIcon from "../../../assets/images/icons/download.png";
import datevIcon from "../../../assets/images/icons/datev.png";
import datevCloseIcon from "../../../assets/images/icons/DatevCloseMark.png";
import datevCheckIcon from "../../../assets/images/icons/DatevCheckMark.png";
import datevTransferedIcon from "../../../assets/images/icons/DatevTransfered.png";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import moment from "moment";
import personIcon from "../../../assets/images/icons/personIcon.png";
import DatevReviewIcon from "../../../assets/images/icons/datevReview.png";
import ReplyArrowIcon from "../../../assets/images/icons/replyArrow.png";
import messageReplyIcon from '../../../assets/images/icons/messageReply.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const getUserProfile = userServices.getUserProfile;

export interface User {
  id: string;
  name: string;
}
export interface CommentProps {
  taskDetails: {
    id: string;
    toBeTagedUsers: User[];
    comments: any;
    status: any;
    notifications: any
  };
  addCommentCall: (comment: {}, files: File[]) => void; // Update to take a string
  commentLoading: boolean;
  commentTxt: any;
  setCommentTxt: any;
  internalAssigneesList: any;
  externalAssigneesList: any;
  isFocused: any;
  setIsFocused: any;
  isFocused2: any;
  setIsFocused2: any;
  setIsFocused1: any;
  username: any;
  moveDocumentToDatevCall: any;
  movingToDatevLoading: any;
  commentFiles: any;
  setCommentFiles: any;
  gridRefComments: any;
  commentReplyTxt: any;
  setCommentReplyTxt: any;
  commentReplyFiles: any;
  setCommentReplyFiles: any;

}

const ArrowTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#5D5FEF',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#5D5FEF',
    borderRadius: '8px',
    padding: '7px',
    fontFamily: 'DM Sans'
  },
}));

const suggestionsState = atom({
  key: 'BandSuggestionsVisible',
  default: false,
});

const mentionTextState = atom({
  key: 'mentionTextState',
  default: '',
});

const Suggestions: React.FC<{
  field: React.RefObject<HTMLTextAreaElement>;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  taskDetails: CommentProps['taskDetails'];
}> = ({ field, value, setValue, taskDetails }) => {
  const [hasSuggestions, setHasSuggestions] = useRecoilState(suggestionsState);
  const [mentionText, setMentionText] = useRecoilState(mentionTextState);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const handleClick = useCallback((name: string) => {
    // const newValue = value + name + ' ';
    const atIndex = value.lastIndexOf('@');
    const newValue = atIndex !== -1
      ? value.substring(0, atIndex + 1) + name + ' '
      : value + name + ' ';
    setValue(newValue); // Update state to reflect new value
    setHasSuggestions(false);
    field.current?.focus(); // Focus immediately
  }, [value, setValue, setHasSuggestions, field]);

  const handleClickAway = useCallback(() => {
    setHasSuggestions(false);
  }, [setHasSuggestions]);
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!hasSuggestions || !taskDetails?.toBeTagedUsers) return;

      const filteredUsers = taskDetails.toBeTagedUsers
        .filter((user, index, self) =>
          user !== null &&
          self.findIndex((u) => u.name === user.name) === index &&
          user.name.toLowerCase().replace(" ", "").includes(mentionText.toLowerCase())
        );

      if (filteredUsers.length === 0) return;

      if (event.key === "ArrowDown") {
        event.preventDefault();
        setSelectedIndex((prev) => (prev + 1) % filteredUsers.length);
      }

      if (event.key === "ArrowUp") {
        event.preventDefault();
        setSelectedIndex((prev) => (prev - 1 + filteredUsers.length) % filteredUsers.length);
      }

      if (event.key === "Enter") {
        event.preventDefault();
        if (selectedIndex >= 0) {
          handleClick(filteredUsers[selectedIndex].name.replace(" ", ""));
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [hasSuggestions, mentionText, selectedIndex, taskDetails, handleClick]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <>
        {hasSuggestions && taskDetails?.toBeTagedUsers?.filter((user, index, self) => user !== null && self.findIndex((u) => u.name === user.name) === index && user.name.toLowerCase().replace(" ", "").includes(mentionText.toLowerCase())).length > 0 && (
          <Fade in={hasSuggestions} timeout={100} onEnter={(node) => node?.scrollTo(0, 0)}>
            <List component={Paper}
              sx={{
                width: '250px',
                maxHeight: '203px', // Use maxHeight instead of fixed height
                overflowY: 'auto',
                boxShadow: '0px 4px 8px 0px #00000040',
                position: 'absolute',
                top: 'calc(100% - 75px)',
                zIndex: 1,
                '& .MuiListItemButton-root': {
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px 16px',
                },
              }}>
              {taskDetails?.toBeTagedUsers?.filter((user, index, self) => user !== null && self.findIndex((u) => u.name === user.name) === index && user.name.toLowerCase().replace(" ", "").includes(mentionText.toLowerCase())).map((user, index) => (
                <ListItemButton key={user.id}
                  onClick={() => handleClick((user.name).replace(" ", ""))}
                  selected={selectedIndex === index}
                  sx={{
                    backgroundColor: selectedIndex === index ? "rgba(0, 0, 0, 0.1)" : "transparent",
                  }}
                >
                  <ProfileImage username={user.name} width='29px' height='29px' fontSize='12.5px' marginRight='6px' />
                  {user.name}
                </ListItemButton>
              ))}
            </List>
          </Fade>
        )}
      </>
    </ClickAwayListener>
  );
};

const SuggestionsField: React.FC<{
  taskDetails: CommentProps['taskDetails'];
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  commentLoading: boolean;
  commentTxt: string;
  addTheComment: any;
  setCommentTxt: any;
  assigneesType: any;
  openAssigneesDropdown: any;
  setOpenAssigneesDropdown: any;
  setAssigneesType: any;
  internalAssigneesList: any;
  externalAssigneesList: any;
  viewers: any;
  setViewers: any;
  username: any;
  setIsFocused: any;
  isFocused: any;
  handleCancel: any;
  handleFileChange: any;
  commentFiles: any;
  setCommentFiles: any;
  search: any,
  setSearch: any,
  filteredInternalAssignees: any,
  filteredExternalAssignees: any,
  setFilteredInternalAssignees: any,
  setFilteredExternalAssignees: any,
  reply: any,
  commentIndex: any;
  commentId: any;
  commentViewers: any;
  commentReplyFiles: any;
  setCommentReplyFiles: any;
  setIsFocused2: any;
  setIsFocused1: any;
  comId: any;
  setComId: any;

}> = (
  { taskDetails,
    value,
    setValue,
    commentLoading,
    commentTxt,
    setCommentTxt,
    addTheComment,
    assigneesType,
    setAssigneesType,
    openAssigneesDropdown,
    setOpenAssigneesDropdown,
    internalAssigneesList,
    externalAssigneesList,
    viewers,
    setViewers,
    isFocused,
    setIsFocused,
    username,
    handleCancel,
    handleFileChange,
    commentFiles,
    setCommentFiles,
    commentReplyFiles,
    setCommentReplyFiles,
    search,
    setSearch,
    filteredInternalAssignees,
    filteredExternalAssignees,
    setFilteredInternalAssignees,
    setFilteredExternalAssignees,
    reply,
    commentIndex,
    commentId,
    commentViewers,
    setIsFocused1,
    setIsFocused2,
    comId,
    setComId

  }) => {
    const setHasSuggestions = useSetRecoilState(suggestionsState);
    const setMentionText = useSetRecoilState(mentionTextState);
    const textFieldRef = useRef<HTMLTextAreaElement | null>(null);
    const suggestionsRef = useRef<HTMLDivElement | null>(null);

    const intl = useIntl();

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      setValue(newValue);
      // setHasSuggestions(newValue.endsWith('@') && newValue.trim() !== '');
      const mentionRegex = /@([^\s]*)$/;
      const match = newValue.match(mentionRegex);

      if (match) {
        setMentionText(match[1]);
        setHasSuggestions(match[1].length > 0 || newValue.endsWith('@'));
      } else {
        setHasSuggestions(false);
      }
    }, [setHasSuggestions, setValue]);

    const handleFocus = useCallback(() => {
      console.log("focus id", commentId)
      if (commentId) {
        setComId(commentId)
        setIsFocused1(false);
        setCommentTxt('');
        setCommentFiles([]);
      }
      else {
        setIsFocused2(false);
        setCommentReplyFiles([]);
        setComId(-1);
      }
      setIsFocused(true);
      if (value.trim() === '') {
        setHasSuggestions(false);
      }
      if (textFieldRef.current) {
        const length = textFieldRef.current.value.length;
        textFieldRef.current.setSelectionRange(length, length);
      }
    }, []);

    const handleBlur = useCallback((event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const relatedTarget = event.relatedTarget as HTMLElement;
      const isClickInsideSuggestions = suggestionsRef.current?.contains(relatedTarget);


      if ((event.target as HTMLElement).classList.contains('greyButton')) {
        setHasSuggestions(false);
        setIsFocused(false);
      }
      if (!isClickInsideSuggestions) {
        setTimeout(() => {
          // setIsFocused(false);
        }, 300);
      }
    }, []);


    useEffect(() => {
      if (isFocused && textFieldRef.current) {
        textFieldRef.current.focus();
        const length = textFieldRef.current.value.length;
        textFieldRef.current.setSelectionRange(length, length);
      }
    }, [isFocused]);

    const assgineeTriggerRef = useRef<HTMLDivElement | null>(null);

    const handleClickOutside = (event: MouseEvent): void => {
      const tooltipElement = document.querySelector(".MuiTooltip-popper");
      const isClickInsideTooltip = tooltipElement?.contains(event.target as Node);
      const isClickInsideAssigneeTrigger = assgineeTriggerRef.current?.contains(event.target as Node);

      if (!isClickInsideTooltip && !isClickInsideAssigneeTrigger) {
        setOpenAssigneesDropdown(false);
      }

    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);



    return (
      <Box position="relative" width={reply ? '96%' : "100%"}>
        < Box display="flex" alignItems="flex-start" width="100%" gap={2}>
          <ProfileImage username={username} width="38px" height="38px" fontSize="18px" flexShrink={0} />
          <TextField
            fullWidth
            inputRef={textFieldRef}
            disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
            multiline={isFocused}
            rows={isFocused ? 4 : 1}
            label={<Message id="task.add-message" />}
            value={value}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}

            InputProps={{
              endAdornment: (
                isFocused ?
                  <HtmlTooltip
                    open={openAssigneesDropdown}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <React.Fragment>
                        <Grid item container maxHeight='160px' padding={1} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                          <Grid item marginBottom={1}>
                            <TextField
                              // ref={assgineeTriggerRef}
                              value={search}
                              fullWidth
                              InputProps={{
                                startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                                style: {
                                  borderRadius: '35px',
                                  fontFamily: 'DM Sans',
                                  height: '32px',
                                  backgroundColor: '#F7F9FF',
                                  // border: '1px solid #E6E6E8'
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "#E6E6E8", // Sets the border color
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "#E6E6E8", // Ensures border color on hover
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#E6E6E8", // Ensures border color when focused
                                  },
                                },
                                "& .MuiInputBase-input::placeholder": {
                                  fontWeight: "normal",
                                },
                              }}
                              placeholder={intl.formatMessage({ id: 'text.search' })}
                              onChange={(e) => {
                                // e.stopPropagation();
                                setSearch(e.target.value);
                                if (e.target.value == "") {
                                  setFilteredExternalAssignees(externalAssigneesList);
                                  setFilteredInternalAssignees(internalAssigneesList)
                                }
                                else {
                                  console.log("filterr", externalAssigneesList)
                                  setFilteredInternalAssignees(internalAssigneesList?.filter((user: any) => user.name.toLowerCase().includes(e.target.value.toLowerCase())))
                                  setFilteredExternalAssignees(externalAssigneesList?.filter((user: any) => user.name.toLowerCase().includes(e.target.value.toLowerCase())))
                                }
                              }
                              }
                            />
                          </Grid>
                          <Grid item container direction='row' columnGap={2} marginBottom={1.5} justifyContent='center'>
                            <Grid item className={classes[assigneesType === 'Internal' ? "internal-external-active" : "internal-external-button"]}
                              ref={assgineeTriggerRef}
                              onClick={(e) => {
                                e.stopPropagation();
                                setAssigneesType('Internal')
                              }}>Internal</Grid>
                            <Grid item className={classes[assigneesType === 'External' ? "internal-external-active" : "internal-external-button"]}
                              ref={assgineeTriggerRef}
                              onClick={(e) => {
                                e.stopPropagation();

                                setAssigneesType('External')
                              }
                              }>External</Grid>
                          </Grid>

                          <Grid item container direction="column" rowGap={1} padding={0.5}>
                            {assigneesType === 'Internal' ? filteredInternalAssignees?.map((i: any, index: any) => (
                              <Grid item container alignItems='center' key={i.id} columnGap={1}
                                className="font-14 normalFontWeight"
                                // className={classes[viewers.find((v: string) => v === i.id) ? 'filter-selected' : 'filter-not-selected']}
                                justifyContent='space-between'
                                onMouseOver={(e) => {
                                  const target = e.target as HTMLElement;
                                  target.style.cursor = 'pointer';
                                }}
                                onClick={(e) => {
                                  // taskDetails.assigneeId = i.id
                                  // forceUpdate();
                                  if (viewers.find((v: string) => v === i.id)) setViewers(viewers.filter((v: string) => v !== i.id))
                                  else setViewers([...viewers, i.id])
                                  // setOpenAssigneesDropdown(false);
                                }}
                              >
                                <Grid item>{i.name}</Grid>
                                <Grid className='bordered-box'
                                  style={{ borderColor: viewers.find((v: string) => v === i.id) ? '#fff' : '#E6E6E8' }}
                                  bgcolor={viewers.find((v: string) => v === i.id) ? '#CCF0EB' : '#fff'}>
                                  {
                                    viewers.find((v: string) => v === i.id) ?
                                      <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '13px', height: '12px' }} />
                                      :
                                      <></>
                                  }
                                  {/* <CheckIcon fontSize='small' htmlColor={viewers.find((v: string) => v === i.id) ? '#00B69B' : '#9E9E9E'} />
                                     */}</Grid>
                                {/* <Grid className='bordered-box' bgcolor={viewers.find((v: string) => v === i.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={viewers.find((v: string) => v === i.id) ? '#00B69B' : '#9E9E9E'} /></Grid> */}
                              </Grid>
                            )) :
                              filteredExternalAssignees?.map((i: any) => (
                                <Grid item container alignItems='center' justifyContent='space-between' key={i.id} columnGap={1}
                                  // className={classes[viewers.find((v: string) => v === i.id) ? 'filter-selected' : 'filter-not-selected']}
                                  className="font-14 normalFontWeight"
                                  onMouseOver={(e) => {
                                    const target = e.target as HTMLElement;
                                    target.style.cursor = 'pointer';
                                  }}

                                  onClick={(e) => {
                                    // taskDetails.assigneeId = i.id
                                    // forceUpdate();
                                    if (viewers.find((v: string) => v === i.id)) setViewers(viewers.filter((v: string) => v !== i.id))
                                    else setViewers([...viewers, i.id])
                                    //setOpenAssigneesDropdown(false);
                                  }}
                                >
                                  <Grid item>{i.name}</Grid>
                                  <Grid className='bordered-box'
                                    style={{ borderColor: viewers.find((v: string) => v === i.id) ? '#fff' : '#E6E6E8' }}
                                    bgcolor={viewers.find((v: string) => v === i.id) ? '#CCF0EB' : '#fff'}>
                                    {
                                      viewers.find((v: string) => v === i.id) ?
                                        <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '13px', height: '12px' }} />
                                        :
                                        <></>
                                    }
                                    {/* <CheckIcon fontSize='small' htmlColor={viewers.find((v: string) => v === i.id) ? '#00B69B' : '#9E9E9E'} />*/}
                                  </Grid>
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }
                  >
                    {/* <InputAdornment position="end" style={{ width: '300px', display: 'flex', alignItems: 'flex-end', marginTop: '25px' }}> */}
                    <InputAdornment position="end" style={{
                      width: '100%',              // Keep full width
                      display: 'flex',
                      alignItems: 'flex-end',     // Change back to flex-end
                      justifyContent: 'flex-end', // Align content to the right
                      position: 'absolute',       // Position absolutely
                      bottom: '8px',              // Position at bottom with some padding
                      right: '0',                 // Align to right edge
                      marginTop: '0',             // Remove top margin
                      padding: '0 14px'           // Add some padding to match input padding
                    }}>

                      <Grid item container direction='row' justifyContent='flex-end' className="bold labels neutral-3" alignItems='center' columnGap={0.5}
                      >
                        <ArrowTooltip title={intl.formatMessage({ id: 'document.you-can-upload' })} placement='top' >
                          <Grid item width='20px' container justifyContent='flex-end' alignItems='center'  >
                            <input
                              type="file"
                              onChange={handleFileChange}
                              disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                              multiple
                              style={{ display: 'none' }} // Hide the default input
                              id="comment-file-upload" // Use an ID for the label
                            />
                            <label htmlFor="comment-file-upload">
                              {/* <AttachFileIcon htmlColor="#93919A" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} /> */}
                              <img src={AttachmentIcon} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', height: '16.67px', width: '12.92px', marginRight: '10px' }} />
                            </label>
                          </Grid>
                        </ArrowTooltip>
                        {!reply ?
                          <>
                            <img src={personIcon} width='16px' height='13px' alt='icon'
                              style={{ cursor: 'pointer' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenAssigneesDropdown(!openAssigneesDropdown)
                              }
                              } />
                            {/* <PeopleAltOutlinedIcon htmlColor="#93919A" style={{ cursor: 'pointer', height: '20px', width: '20px' }} onClick={(e) => {
                          e.stopPropagation();
                          setOpenAssigneesDropdown(!openAssigneesDropdown)
                        }
                        } /> */}
                            <span style={{ paddingTop: '5px', display: 'flex' }}><Message id="text.visible-to" className="Field-input labels font-weight-400 neutral-3" /> {viewers?.length === 0 ? <Message id="text.all" className="Field-input labels font-weight-400 neutral-3" /> : <Message id="text.selected" className="Field-input labels font-weight-400 neutral-3" />}</span>
                          </>
                          :
                          <></>
                        }

                      </Grid>
                    </InputAdornment>
                  </HtmlTooltip>
                  : ""
              ),
              style: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }
            }}
            InputLabelProps={{
              shrink: Boolean(value) || isFocused,
            }}
            sx={{
              flexGrow: 1,
              fontFamily: 'DM Sans',
              position: 'relative',      // Add this for absolute positioning context
              display: 'flex',
              flexDirection: 'column',
              // marginBottom: value ? '0px' : '45px',
              marginBottom: isFocused ? 'undefined' : '45px',
              '& .MuiOutlinedInput-root': {
                height: isFocused ? 'auto' : '38px',
                paddingBottom: isFocused ? '40px' : '38px',  // Add padding at bottom to make room for adornment
                display: 'flex',
                flexDirection: 'column', // Add this to ensure column layout
                '& .MuiInputBase-input': {
                  padding: isFocused ? '0px' : '10px 14px',
                  width: '100%' // Ensure input takes full width
                },
                '&.Mui-focused fieldset': {
                  borderColor: isFocused ? 'primary.main' : 'rgba(0, 0, 0, 0.23)',
                },
              },
              '& .MuiInputLabel-root': {
                top: isFocused || value ? 0 : '50%',
                transform: isFocused || value ? 'translate(14px, -50%) scale(0.75)' : 'translate(14px, -50%) scale(1)',
                transition: 'top 0.3s, transform 0.3s',
                display: 'flex',
                flexDirection: 'column',
              },
              '& .css-jw2wop-MuiInputBase-root-MuiOutlinedInput-root ': {
                padding: '8px 15px 8px 15px',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end'

              }
            }}
          />

        </Box>
        {
          isFocused && (
            <Box position="absolute" left='80px' top="calc(100% - 50px)" zIndex={1} ref={suggestionsRef}>
              <Suggestions field={textFieldRef} value={value} setValue={setValue} taskDetails={taskDetails} />
            </Box>
          )
        }
        {
          ((commentReplyFiles.length > 0 && commentId)) ?
            <Grid item container direction='column' rowGap={1} paddingLeft='51px' paddingRight='5px' maxHeight='160px' display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }} marginTop={1}>
              {commentReplyFiles?.map((i: any, index: any) => (
                <Grid item container direction='row' key={index}
                  padding={1.2} xs={9} style={{
                    border: '1px solid #C3C2C7',
                    borderRadius: '8px'
                  }}
                  alignItems='center'
                  justifyContent='space-between'
                // position='relative'
                // columnSpacing={2}
                // margin={0.5}
                >
                  <Grid item container className='bold' xs={8} columnGap={2} alignItems='center'> <InsertDriveFileIcon fontSize='medium' htmlColor='#93919A' /> {i.name} <br />{i.size} KB</Grid>
                  <Grid item container xs={4} justifyContent='flex-end' >
                    <Grid item container direction='row' columnGap={1} justifyContent='flex-end' alignItems='center'
                      onClick={() => {
                        if (commentId) {
                          if (index > -1 && index < commentReplyFiles.length) {
                            const tmp = commentReplyFiles.filter((item: any, ind: any) => ind !== index);
                            setCommentReplyFiles(tmp)
                          }

                        }
                        else {
                          if (index > -1 && index < commentFiles.length) {
                            const tmp = commentFiles.filter((item: any, ind: any) => ind !== index);
                            setCommentFiles(tmp)
                          }

                        }

                      }}>

                      <CloseIcon htmlColor='black' style={{ width: "19px", height: "19px", marginTop: '3px', cursor: 'pointer' }} />

                    </Grid>
                  </Grid>
                </Grid>
              ))
              }
            </Grid>
            : (commentFiles.length > 0 && !commentId) ?
              <Grid item container direction='column' rowGap={1} paddingLeft='51px' paddingRight='5px' maxHeight='160px' display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }} marginTop={1}>
                {commentFiles?.map((i: any, index: any) => (
                  <Grid item container direction='row' key={index}
                    padding={1.2} xs={9} style={{
                      border: '1px solid #C3C2C7',
                      borderRadius: '8px'
                    }}
                    alignItems='center'
                    justifyContent='space-between'
                  // position='relative'
                  // columnSpacing={2}
                  // margin={0.5}
                  >
                    <Grid item container className='bold' xs={8} columnGap={2} alignItems='center'> <InsertDriveFileIcon fontSize='medium' htmlColor='#93919A' /> {i.name} <br />{i.size} KB</Grid>
                    <Grid item container xs={4} justifyContent='flex-end' >
                      <Grid item container direction='row' columnGap={1} justifyContent='flex-end' alignItems='center'
                        onClick={() => {
                          if (commentId) {
                            if (index > -1 && index < commentReplyFiles.length) {
                              const tmp = commentReplyFiles.filter((item: any, ind: any) => ind !== index);
                              setCommentReplyFiles(tmp)
                            }

                          }
                          else {
                            if (index > -1 && index < commentFiles.length) {
                              const tmp = commentFiles.filter((item: any, ind: any) => ind !== index);
                              setCommentFiles(tmp)
                            }

                          }

                        }}>

                        <CloseIcon htmlColor='black' style={{ width: "19px", height: "19px", marginTop: '3px', cursor: 'pointer' }} />

                      </Grid>
                    </Grid>
                  </Grid>
                ))
                }
              </Grid>
              :
              <></>
        }


        {/* 
        <Grid item container xs={12} alignItems='center' direction='column' maxHeight='60px' display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }} >
          {commentFiles?.map((i: any, index: any) => (
            <Grid item container direction='row' className='black' justifyContent='flex-end' columnGap={0.5}>
              <Grid item>{i.name}</Grid>
              <Grid item
                onClick={() => {
                  if (index > -1 && index < commentFiles.length) {
                    const tmp = commentFiles.filter((item: any, ind: any) => ind !== index);
                    setCommentFiles(tmp)
                  }
                  console.log("commentfiles", commentFiles)
                }}
              ><CloseIcon htmlColor='black' style={{ width: "19px", height: "19px", marginTop: '3px', cursor: 'pointer' }} /></Grid>
            </Grid>

          ))}
        </Grid> */}

        {
          isFocused && (
            <Grid item container direction='row' marginTop={1} marginBottom={2} columnGap={1} justifyContent='flex-start' marginLeft={7}>
              <Grid item width='80px' alignSelf='flex-end'>
                <button className='blueButton'
                  disabled={commentLoading || ((!commentId && !commentTxt && commentFiles?.length === 0) || (commentId && !commentTxt && commentReplyFiles?.length === 0))}
                  onClick={() => addTheComment(commentId, commentViewers)}>
                  {commentLoading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                    : <Message id="button.save" className='Field-input font-weight-700 labelsLinks' />}
                </button>
              </Grid>
              <Grid item width='80px'>
                <button className='greyButton' style={{ fontFamily: 'DM Sans', fontSize: '14px' }} onClick={() => handleCancel()}>Cancel</button>
              </Grid>
            </Grid>
          )
        }

      </Box >
    );
  };

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: '0px 2px 4px 0px #0000001F',
    backgroundColor: '#FEFEFF',
    borderRadius: '10px',
    padding: 0,
    color: 'black',
  },
}));

const Messages = (props: CommentProps) => {
  const {
    taskDetails,
    addCommentCall,
    commentLoading,
    commentTxt,
    setCommentTxt,
    internalAssigneesList,
    externalAssigneesList,
    isFocused,
    setIsFocused,
    isFocused2,
    setIsFocused2,
    username,
    moveDocumentToDatevCall,
    movingToDatevLoading,
    commentFiles,
    setCommentFiles,
    gridRefComments,
    commentReplyTxt,
    setCommentReplyTxt,
    commentReplyFiles,
    setCommentReplyFiles, } = props;

  const [openAssigneesDropdown, setOpenAssigneesDropdown] = useState(false);
  const [assigneesType, setAssigneesType] = useState('External');
  const [viewers, setViewers] = useState([]);

  const [transferDatevHover, setTransferDatevHover] = useState(false);
  const [docIndex, setDocIndex] = useState(-1);
  const [commentIndex, setCommentIndex] = useState(-1);

  const [replyDocIndex, setReplyDocIndex] = useState(-1);
  const [replyCommentIndex, setReplyCommentIndex] = useState(-1);

  const [commentId, setCommentId] = useState(-1);
  const [docId, setDocId] = useState(-1)

  const token = useAppSelector((state) => state.userData.token);

  const [search, setSearch] = useState<string>();
  const [filteredInternalAssignees, setFilteredInternalAssignees] = useState(internalAssigneesList);
  const [filteredExternalAssignees, setFilteredExternalAssignees] = useState(externalAssigneesList);

  // const commentNotificationsCount = taskDetails?.notifications?.filter((n: any) => n.type === 1).length;
  const [reply, setReply] = useState(false);
  const [comId, setComId] = useState(-1);
  const replies = [
    {
      "id": "294a77b8-1d5c-47fe-b050-c9812f3fb63d",
      "isCreator": true,
      "content": "CHECKING ",
      "creatorId": "5f33a917-50a9-4960-9895-08dcb6270a15",
      "creationDate": "12/2/2024",
      "creator": "Faten Tax Advisor Company",
      "visibility": 1,
      "documents": [
        {
          "id": "22e58eba-55ae-4284-95c7-73e3290f4e52",
          "name": "Screenshot (1).png",
          "blobName": null,
          "uri": "https://taxmationstorageval.blob.core.windows.net/blobs/tasks/cca8b6a3-2146-46f2-37fd-08dd12cc165e/22e58eba-55ae-4284-95c7-73e3290f4e52_Screenshot %281%29.png",
          "isDatev": false,
          "isAttachment": false,
          "size": 184393,
          "creationDate": "12/2/2024 2:34:12 PM"
        }
      ]
    },
  ]



  const addTheComment = (commentId: string, commentViewers: any) => {
    let mentions: string[] = [];
    // if (textFieldRef.current) {
    // let comment = textFieldRef.current.value;
    // let commentTmp = localStorage.getItem('comment');
    taskDetails?.toBeTagedUsers?.filter((c: any) => c !== null)?.map((u: any) => {
      if (commentTxt?.includes("@")) {
        if (commentTxt?.includes(u.name))
          mentions.push(u.id)
      }
    })
    // }

    let commentObj = {
      taskId: taskDetails.id,
      content: commentId ? commentReplyTxt : commentTxt,
      tags: mentions,
      visibility: viewers.length === 0 ? 1 : 2,
      viewers: commentId ? commentViewers : viewers,
      commentId: commentId

    }

    // console.log("commentObj", commentObj);
    console.log("commentId", commentId)

    addCommentCall(commentObj, commentId ? commentReplyFiles : commentFiles);
    // handleCancel();
  };
  const handleCancel = () => {

    // if (comId !== -1) {
    console.log("testt")

    setIsFocused2(false);
    setCommentReplyTxt('');
    setCommentReplyFiles([]);

    // }
    // else {
    console.log("checkk here")
    setIsFocused(false);
    setCommentTxt('');
    setCommentFiles([]);
    setComId(-1);
    // }
    setViewers([]);
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = event.target.files;
    console.log("uploadedFiles", uploadedFiles)
    if (uploadedFiles) {
      const filesArray = Array.from(uploadedFiles);
      if (comId !== -1) {
        const combinedArray = [...filesArray, ...commentReplyFiles];
        setCommentReplyFiles(combinedArray);
        console.log('comment reply files', combinedArray)


      }
      else {
        const combinedArray = [...filesArray, ...commentFiles];
        setCommentFiles(combinedArray);
        console.log("com files", combinedArray)

      }

      // uploadTaskDocumentCall(filesArray);
    }
  };

  const downloadFile = (uri: string, filename: string) => {
    const link = document.createElement('a');
    link.href = uri;
    link.download = filename;

    // Append to the body to ensure it's in the document
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  };

  const gridRef = useRef<HTMLDivElement | null>(null); useEffect(() => {

    if (taskDetails?.notifications?.length > 0 && taskDetails?.notifications?.find((n: any) => n.type === 1) && gridRef.current) {
      gridRef.current.scrollTo({
        top: gridRef.current.scrollHeight,
        behavior: "smooth", // Optional: "auto" for immediate scrolling
      });
    }
  }, [taskDetails]);

  const [newMessages, setNewMessages] = useState<string[]>([]);

  useEffect(() => {
    if (taskDetails) {
      const newIds = taskDetails.notifications
        .filter((n: any) => n.type === 1)
        .map((n: any) => n.commentId || n.replyId);
      setNewMessages(newIds);
    }
  }, [taskDetails]);

  return (
    <Grid item container direction='column' rowGap={2} ref={gridRefComments}>
      {/* {!addComment ?
      <Grid item width='150px' onClick={() => setAddComment(true)}><button className='blueButton'>Add Comment</button></Grid>
      :
      <></>} */}
      {/* {addComment ? */}
      <Grid item container direction='column' rowGap={1}>
        <RecoilRoot>
          {/* <CssBaseline /> */}
          <SuggestionsField
            comId={comId}
            setComId={setComId}
            key={`suggestions-field-${1}`}
            taskDetails={taskDetails}
            value={commentTxt}
            setValue={setCommentTxt} // Pass the setter directly
            commentLoading={commentLoading}
            commentTxt={commentTxt}
            setCommentTxt={setCommentTxt}
            commentReplyFiles={commentReplyFiles}
            setCommentReplyFiles={setCommentReplyFiles}
            addTheComment={addTheComment}
            openAssigneesDropdown={openAssigneesDropdown}
            setOpenAssigneesDropdown={setOpenAssigneesDropdown}
            assigneesType={assigneesType}
            setAssigneesType={setAssigneesType}
            internalAssigneesList={internalAssigneesList}
            externalAssigneesList={externalAssigneesList}
            viewers={viewers}
            setViewers={setViewers}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            setIsFocused1={setIsFocused}
            setIsFocused2={setIsFocused2}
            username={username}
            handleCancel={handleCancel}
            handleFileChange={handleFileChange}
            commentFiles={commentFiles}
            setCommentFiles={setCommentFiles}
            search={search}
            setSearch={setSearch}
            filteredExternalAssignees={filteredExternalAssignees}
            filteredInternalAssignees={filteredInternalAssignees}
            setFilteredInternalAssignees={setFilteredInternalAssignees}
            setFilteredExternalAssignees={setFilteredExternalAssignees}
            reply={false}
            commentIndex={null}
            commentId={''}
            commentViewers={''}

          />
        </RecoilRoot>

        {/* {commentTxt && (
          <Grid item container direction='row' marginTop={3} marginBottom={2} columnGap={1} justifyContent='flex-start' marginLeft={7}>
            <Grid item width='80px' alignSelf='flex-end'>
              <button className='blueButton'
                disabled={commentLoading || !commentTxt}
                onClick={() => addTheComment()}>
                {commentLoading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                  : <Message id="button.save" className='Field-input font-weight-700 labelsLinks' />}
              </button>
            </Grid>
            <Grid item width='80px'>
              <button className='greyButton' onClick={handleCancel}>Cancel</button>
            </Grid>
          </Grid>
        )} */}


      </Grid>
      {/* :
      <></>
    } */}
      <Grid item container style={{ overflowY: 'auto', maxHeight: '300px' }} rowGap={2} ref={gridRef}>
        {taskDetails?.comments?.map((c: any, commentIn: any) => (
          <Grid item container direction='column' key={c.id} rowGap={0.5} margin={1}>
            <Grid item container direction='row' columnGap={1.5} alignItems="flex-start">
              <ProfileImage username={c.creator} width='38px' height='38px' fontSize="18px" />
              <Grid item className='labels-extra-bold' sx={{}}>{c.creator}
                <br />
                <span className="labels neutral-3 normalFontWeight">{internalAssigneesList?.find((i: any) => i.id === c.creatorId) ? "Internal"
                  : externalAssigneesList?.find((i: any) => i.id === c.creatorId) ? "External"
                    : ""}
                </span>
              </Grid>
              <Grid item className='neutral-3'>{moment(c.creationDate).format('DD.MM.YYYY h:mm A')}</Grid>
              <Grid item className="lables neutral-3" container alignItems='center' width='200px' columnGap={0.8}>
                <img src={personIcon} width='16px' height='12px' alt='icon' />
                {/* <PeopleAltOutlinedIcon htmlColor="#93919A" /> */}
                <Message id="text.visible-to" className='Field-input lables neutral-3' /> {c.visibility === 1 ? <Message id="text.all" className='Field-input lables neutral-3' /> : <Message id="text.selected" className='Field-input lables neutral-3' />}</Grid>
            </Grid>
            <Grid item marginLeft='45px' padding='4px' style={{
              border: (
                //   taskDetails?.notifications?.filter((n: any) => n.type === 1).length > 0 && 
                // (commentIn >= taskDetails?.comments?.length - (taskDetails?.notifications?.filter((n: any) => n.type === 1).length)) &&
                newMessages.includes(c.id) &&
                !c.isCreator
                && c.content) ? '1px solid #5D5FEF' : '', borderRadius: '8px',
              backgroundColor: (
                //   taskDetails?.notifications?.filter((n: any) => n.type === 1).length > 0 &&
                // (commentIn >= taskDetails?.comments?.length - (taskDetails?.notifications?.filter((n: any) => n.type === 1).length)) &&
                newMessages.includes(c.id) &&
                !c.isCreator
                && c.content) ? '#E5F0FC' : ''

            }}>{c.content}</Grid>
            <Grid item container direction='column' rowGap={1} paddingLeft='49px' paddingRight='5px' maxHeight='160px' display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }} >
              {c.documents?.map((i: any, index: any) => (
                <Grid item container direction='row' key={index}
                  padding={1.2} xs={9} style={{
                    border: '1px solid #C3C2C7',
                    borderRadius: '8px'
                  }}
                  alignItems='center'
                  justifyContent='space-between'
                  position='relative'
                // columnSpacing={2}
                // margin={0.5}
                >
                  <Grid item container className='bold' xs={8} columnGap={2} alignItems='center'> <InsertDriveFileIcon fontSize='medium' htmlColor='#93919A' /> {i.name} <br />{i.size} KB</Grid>
                  <Grid item container xs={4} justifyContent='flex-end' >
                    <Grid item container direction='row' columnGap={1} justifyContent='flex-end' alignItems='center'>

                      {/* {localStorage.getItem('userType') === '1' && !i.isDatev ?
                        <Grid item container direction='row' bgcolor={movingToDatevLoading && index === docIndex ? "#CCF0EB" : ""}
                          style={{ border: "solid 1px #E6E6E8", borderRadius: '8px', cursor: 'pointer' }}
                          width={transferDatevHover && index === docIndex ? '200px' : '60px'}
                          justifyContent='center'
                          alignItems='center'
                          padding={0.3}
                          columnGap={1}
                          onMouseOver={() => {
                            setTransferDatevHover(true);
                            setDocIndex(index)
                          }}
                          onMouseLeave={() => {
                            if (!movingToDatevLoading) {
                              setTransferDatevHover(false);
                              setDocIndex(-1)
                            }
                          }
                          }
                          onClick={() => moveDocumentToDatevCall(i.id)}
                        >
                          <Grid item container direction='row'
                            width={transferDatevHover && index === docIndex ? '160px' : '15px'}
                            alignItems='center'
                            padding={0.2}
                            columnGap={0.5}
                            justifyContent='flex-end'>
                            {transferDatevHover && index === docIndex ?
                              <><span className="labels neutral-2 font-14" style={{ textAlign: 'center' }}>
                                {movingToDatevLoading ? "Aproving for Datev ..." : "Approve for DATEV"}</span>
                                <img src={datevCheckIcon} width='12px' height='17px' /></>
                              :
                              <img src={datevCloseIcon} width='12px' height='17px' />
                            }
                          </Grid>
                          <Grid item container alignItems='center' width='20px'>
                            <img src={datevIcon} width='17px' height='17px' />
                          </Grid>
                        </Grid>
                        : i.isDatev ? (
                          <Grid item style={{ position: 'absolute', right: '8%', bottom: '20%' }}><img src={datevTransferedIcon} /> </Grid>
                        )
                          :
                        <></>} */}

                      {/* {!i.isDatev && localStorage.getItem('userType') === '1' ? */}
                      {!i.isAttachment && c.isCreator ?
                        <Grid item container direction='row'
                          //  bgcolor={movingToDatevLoading && index === docIndex && commentIndex === commentIn ? "#CCF0EB" : ""}
                          bgcolor={movingToDatevLoading && docId === i.id && commentId === c.id ? "#CCF0EB" : ""}

                          style={{ border: "solid 1px #93919A", borderRadius: '8px', cursor: movingToDatevLoading ? 'unset' : 'pointer' }}
                          // width={transferDatevHover && index === docIndex && commentIndex === commentIn && localStorage.getItem('userType') === '1' ? '200px' : transferDatevHover && index === docIndex && commentIndex === commentIn && localStorage.getItem('userType') === '2' ? '125px' : localStorage.getItem('userType') === '1' ? '60px' : '40px'}
                          width={transferDatevHover && docId === i.id && commentId === c.id && localStorage.getItem('userType') === '1' ? '200px' : transferDatevHover && docId === i.id && commentId === c.id && localStorage.getItem('userType') === '2' ? '125px' : localStorage.getItem('userType') === '1' ? '60px' : '40px'}
                          justifyContent='center'
                          alignItems='center'
                          padding={0.5}
                          columnGap={1}
                          onMouseOver={() => {
                            if (!movingToDatevLoading) {
                              setTransferDatevHover(true);
                              // setDocIndex(index)
                              // setCommentIndex(commentIn)

                              setCommentId(c.id);
                              setDocId(i.id)

                            }
                          }}
                          onMouseLeave={() => {
                            if (!movingToDatevLoading) {
                              setTransferDatevHover(false);
                              // setDocIndex(-1)
                              // setCommentIndex(-1)

                              setCommentId(-1);
                              setDocId(-1)
                            }
                          }
                          }
                          onClick={() => moveDocumentToDatevCall(i.id)}
                        >
                          <Grid item container direction='row'
                            // width={transferDatevHover && index === docIndex && commentIndex === commentIn ? '160px' : '15px'}
                            width={transferDatevHover && docId === i.id && commentId === c.id ? '160px' : '15px'}
                            alignItems='center'
                            padding={0.2}
                            columnGap={0.8}
                            justifyContent='flex-end'>
                            {/* {transferDatevHover && index === docIndex && commentIndex === commentIn ? */}
                            {transferDatevHover && docId === i.id && commentId === c.id ?
                              <><span className="labels neutral-2 font-14" style={{ textAlign: 'center' }}>
                                {/* {movingToDatevLoading ? "Aproving for Datev ..." : "Approve for DATEV"} */}
                                {localStorage.getItem('userType') === '1' ? "Approve for DATEV" : "Ready for STB"}
                              </span>
                                <img src={datevCheckIcon} width='12px' height='17px' /></>
                              :
                              <img src={datevCloseIcon} width='12px' height='17px' />
                            }
                          </Grid>
                          {localStorage.getItem('userType') === '1' ?
                            <Grid item container alignItems='center' width='20px'>
                              <img src={datevIcon} width='17px' height='17px' alt='icon' />
                            </Grid>

                            :
                            <></>
                          }

                        </Grid>
                        : i.isDatev ? (
                          <Grid item style={{ position: 'absolute', right: '8%', bottom: '20%' }}><img src={datevTransferedIcon} alt='icon' /> </Grid>
                        )
                          : i.isAttachment && !i.isDatev && localStorage.getItem('userType') === '2' && c.isCreator ?
                            <Grid item container direction='row'
                              style={{ border: "solid 1px #93919A", borderRadius: '8px' }}
                              width='150px'
                              justifyContent='center'
                              alignItems='center'
                              padding={0.5}
                              columnGap={1}
                            >
                              <><span className="labels neutral-2 font-14" style={{ textAlign: 'center' }}>
                                Under Review
                              </span>
                                <img src={DatevReviewIcon} width='15px' height='17px' alt='icon' /></>


                            </Grid>
                            :
                            <></>
                      }

                      {/* : i.isDatev ? (
                          <Grid item style={{ position: 'absolute', right: '8%', bottom: '20%' }}><img src={datevTransferedIcon} /> </Grid>
                        )
                          :
                          <></>
                      } */}

                      <Grid container width='30px'
                        textAlign='center'
                        onClick={() => {
                          downloadFile(i.uri, i.name);
                        }}>
                        <img alt='icon' src={downloadIcon} width={17} height={17} style={{ cursor: 'pointer' }} /> </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))
              }
            </Grid>
            <Grid container className="neutral-3 pointerText" paddingLeft='49px' paddingRight='5px' alignItems='center' columnGap={0.5}
              onClick={() => {
                // setReply(true)
                // // setCommentIndex(commentIn);
                // setComId(c.id)

                // setCommentId(c.id);

                if (!reply) {
                  setReply(true)
                  setCommentIndex(commentIn);
                  setCommentId(c.id);
                  setComId(c.id)
                }
                else if (reply && commentId !== c.id) {
                  setCommentIndex(commentIn);
                  setCommentId(c.id);
                  setComId(c.id)
                }
                else {
                  setReply(false)
                  setComId(-1)
                  setCommentId(-1);
                }

              }}>
              <img src={ReplyArrowIcon} alt='icon' width='20px' height='20px' />
              <Message id="text.reply" className="Field-input desktop-paragraph font-weight-400 .neutral-3 pointerText" />
            </Grid>
            {c.replies.length > 0 ?
              <>
                <Grid container className="pointerText blue" paddingLeft='49px' paddingRight='5px' alignItems='center' columnGap='10px'
                  onClick={() => {
                    // setReply(!reply)
                    // setCommentIndex(commentIn)
                    // if (reply) setCommentIndex(c.id)
                    // else setComId(-1)

                    if (!reply) {
                      setReply(true)
                      setCommentIndex(commentIn);
                      setCommentId(c.id);
                      setComId(c.id)
                    }
                    else if (reply && commentId !== c.id) {
                      setCommentIndex(commentIn);
                      setCommentId(c.id);
                      setComId(c.id)
                    }
                    else {
                      setReply(false)
                      setComId(-1)
                      setCommentId(-1);
                    }
                  }
                  }>
                  <img src={messageReplyIcon} alt='icon' width='18px' height='16.96px' />
                  <span>{c.replies?.length} {c.replies?.length > 1 ? "Replies" : 'Reply'} </span>
                  {reply && commentIndex === commentIn ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Grid>
              </>
              :
              <></>
            }
            {reply && commentIndex === commentIn ?
              <Grid container width='100%'>
                {c.replies?.map((r: any, replyIn: any) => (
                  <Grid item container direction='column' key={r.id} rowGap={0.5} margin={1} paddingLeft={7}>
                    <Grid item container direction='row' columnGap={1.5} alignItems="flex-start">
                      <ProfileImage username={r.creator} width='38px' height='38px' fontSize="18px" />
                      <Grid item className='labels-extra-bold' sx={{}}>{r.creator}
                        <br />
                        <span className="labels neutral-3 normalFontWeight">{internalAssigneesList?.find((i: any) => i.id === r.creatorId) ? "Internal"
                          : externalAssigneesList?.find((i: any) => i.id === r.creatorId) ? "External"
                            : ""}
                        </span>
                      </Grid>
                      <Grid item className='neutral-3'>{moment(r.creationDate).format('DD.MM.YYYY')}</Grid>
                      {/* <Grid item className="lables neutral-3" container alignItems='center' width='200px' columnGap={0.8}>
                        <img src={personIcon} width='16px' height='12px' alt='icon' />
                        Visible to: {c.visibility === 1 ? "all" : "selected"}
                      </Grid> */}
                    </Grid>
                    <Grid item marginLeft='45px' padding='4px' style={{
                      border: (
                        //   taskDetails?.notifications?.filter((n: any) => n.type === 1).length > 0 &&
                        // (replyIn >= c.replies?.length - (taskDetails?.notifications?.filter((n: any) => n.type === 1).length)) &&
                        newMessages.includes(r.id)
                        && !r.isCreator && r.content) ? '1px solid #5D5FEF' : '', borderRadius: '8px',
                      backgroundColor: (
                        //   taskDetails?.notifications?.filter((n: any) => n.type === 1).length > 0 &&
                        // (replyIn >= c.replies?.length - (taskDetails?.notifications?.filter((n: any) => n.type === 1).length)) 
                        newMessages.includes(r.id)
                        && !r.isCreator && r.content) ? '#E5F0FC' : ''
                    }}>{r.content}</Grid>
                    <Grid item container direction='column' rowGap={1} paddingLeft='49px' paddingRight='5px' maxHeight='160px' display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }} >
                      {r.documents?.map((i: any, index: any) => (
                        <Grid item container direction='row' key={i.id}
                          padding={1.2} xs={9} style={{
                            border: '1px solid #C3C2C7',
                            borderRadius: '8px'
                          }}
                          alignItems='center'
                          justifyContent='space-between'
                          position='relative'
                        >
                          <Grid item container className='bold' xs={8} columnGap={2} alignItems='center'> <InsertDriveFileIcon fontSize='medium' htmlColor='#93919A' /> {i.name} <br />{i.size} KB</Grid>
                          <Grid item container xs={4} justifyContent='flex-end' >
                            <Grid item container direction='row' columnGap={1} justifyContent='flex-end' alignItems='center'>

                              {!i.isAttachment && r.isCreator ?
                                <Grid item container direction='row'
                                  // bgcolor={movingToDatevLoading && index === docIndex && commentIndex === commentIn ? "#CCF0EB" : ""}
                                  bgcolor={movingToDatevLoading && docId === i.id && commentId === r.id ? "#CCF0EB" : ""}

                                  style={{ border: "solid 1px #93919A", borderRadius: '8px', cursor: movingToDatevLoading ? 'unset' : 'pointer' }}
                                  // width={transferDatevHover && index === docIndex && commentIndex === commentIn && localStorage.getItem('userType') === '1' ? '200px' : transferDatevHover && index === docIndex && commentIndex === commentIn && localStorage.getItem('userType') === '2' ? '125px' : localStorage.getItem('userType') === '1' ? '60px' : '40px'}
                                  width={transferDatevHover && docId === i.id && commentId === r.id && localStorage.getItem('userType') === '1' ? '200px' : transferDatevHover && docId === i.id && commentId === r.id && localStorage.getItem('userType') === '2' ? '125px' : localStorage.getItem('userType') === '1' ? '60px' : '40px'}

                                  justifyContent='center'
                                  alignItems='center'
                                  padding={0.5}
                                  columnGap={1}
                                  onMouseOver={() => {
                                    if (!movingToDatevLoading) {
                                      setTransferDatevHover(true);
                                      // setDocIndex(index)
                                      // setCommentIndex(commentIn)

                                      setCommentId(r.id);
                                      setDocId(i.id)
                                    }
                                  }}
                                  onMouseLeave={() => {
                                    if (!movingToDatevLoading) {
                                      setTransferDatevHover(false);
                                      // setDocIndex(-1)
                                      // setCommentIndex(-1)


                                      setCommentId(-1);
                                      setDocId(-1)
                                    }
                                  }
                                  }
                                  onClick={() => moveDocumentToDatevCall(i.id)}
                                >
                                  <Grid item container direction='row'
                                    // width={transferDatevHover && index === docIndex && commentIndex === commentIn ? '160px' : '15px'}
                                    width={transferDatevHover && docId === i.id && commentId === r.id ? '160px' : '15px'}
                                    alignItems='center'
                                    padding={0.2}
                                    columnGap={0.8}
                                    justifyContent='flex-end'>
                                    {/* {transferDatevHover && index === docIndex && commentIndex === commentIn ? */}
                                    {transferDatevHover && docId === i.id && commentId === r.id ?

                                      <><span className="labels neutral-2 font-14" style={{ textAlign: 'center' }}>
                                        {/* {movingToDatevLoading ? "Aproving for Datev ..." : "Approve for DATEV"} */}
                                        {localStorage.getItem('userType') === '1' ? "Approve for DATEV" : "Ready for STB"}
                                      </span>
                                        <img src={datevCheckIcon} width='12px' height='17px' /></>
                                      :
                                      <img src={datevCloseIcon} width='12px' height='17px' />
                                    }
                                  </Grid>
                                  {localStorage.getItem('userType') === '1' ?
                                    <Grid item container alignItems='center' width='20px'>
                                      <img src={datevIcon} width='17px' height='17px' alt='icon' />
                                    </Grid>

                                    :
                                    <></>
                                  }

                                </Grid>
                                : i.isDatev ? (
                                  <Grid item style={{ position: 'absolute', right: '8%', bottom: '20%' }}><img src={datevTransferedIcon} alt='icon' /> </Grid>
                                )
                                  : i.isAttachment && !i.isDatev && localStorage.getItem('userType') === '2' && r.isCreator ?
                                    <Grid item container direction='row'
                                      style={{ border: "solid 1px #93919A", borderRadius: '8px' }}
                                      width='150px'
                                      justifyContent='center'
                                      alignItems='center'
                                      padding={0.5}
                                      columnGap={1}
                                    >
                                      <><span className="labels neutral-2 font-14" style={{ textAlign: 'center' }}>
                                        Under Review
                                      </span>
                                        <img src={DatevReviewIcon} width='15px' height='17px' alt='icon' /></>


                                    </Grid>
                                    :
                                    <></>
                              }

                              <Grid container width='30px'
                                textAlign='center'
                                onClick={() => {
                                  downloadFile(i.uri, i.name);
                                }}>
                                <img alt='icon' src={downloadIcon} width={17} height={17} style={{ cursor: 'pointer' }} /> </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))
                      }
                    </Grid>
                  </Grid>
                ))}
                <Grid item container direction='column' rowGap={1} paddingLeft={7} paddingRight={1.8} marginTop={1}>
                  <RecoilRoot>
                    <SuggestionsField
                      comId={comId}
                      setComId={setComId}
                      taskDetails={taskDetails}
                      value={commentReplyTxt}
                      setValue={setCommentReplyTxt}
                      commentLoading={commentLoading}
                      commentTxt={commentReplyTxt}
                      setCommentTxt={setCommentReplyTxt}
                      addTheComment={addTheComment}
                      openAssigneesDropdown={openAssigneesDropdown}
                      setOpenAssigneesDropdown={setOpenAssigneesDropdown}
                      assigneesType={assigneesType}
                      setAssigneesType={setAssigneesType}
                      internalAssigneesList={internalAssigneesList}
                      externalAssigneesList={externalAssigneesList}
                      viewers={viewers}
                      setViewers={setViewers}
                      isFocused={isFocused2}
                      setIsFocused={setIsFocused2}
                      setIsFocused1={setIsFocused}
                      setIsFocused2={setIsFocused2}
                      username={username}
                      handleCancel={handleCancel}
                      handleFileChange={handleFileChange}
                      commentFiles={commentFiles}
                      setCommentFiles={setCommentFiles}
                      commentReplyFiles={commentReplyFiles}
                      setCommentReplyFiles={setCommentReplyFiles}
                      search={search}
                      setSearch={setSearch}
                      filteredExternalAssignees={filteredExternalAssignees}
                      filteredInternalAssignees={filteredInternalAssignees}
                      setFilteredInternalAssignees={setFilteredInternalAssignees}
                      setFilteredExternalAssignees={setFilteredExternalAssignees}
                      reply={true}
                      commentIndex={commentIndex}
                      commentId={c.id}
                      key={`suggestions-field-${commentIndex}`}
                      commentViewers={c.viewers}

                    />
                  </RecoilRoot>


                </Grid>
              </Grid>
              :
              <></>
            }
          </Grid>
        ))}
      </Grid>

    </Grid>
  );
};

export default Messages;