import React, { useState, useEffect } from 'react';
import UserBlackIcon from "../assets/images/icons/navbar/userBlack.png";
import UserBlueIcon from "../assets/images/icons/navbar/userBlue.png";
import taskBlackIcon from "../assets/images/icons/navbar/taskBlack.png";
import taskBlueIcon from "../assets/images/icons/navbar/taskBlue.png";
import clientsBlackIcon from "../assets/images/icons/navbar/clientsBlackIcon.png";
import newsBlack from "../assets/images/icons/navbar/newsBlack.png";
import documentsBlack from "../assets/images/icons/navbar/documentBlack.png";
import modulesBlack from "../assets/images/icons/navbar/moduleBlack.png";
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import GroupIcon from '@mui/icons-material/Group';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import { selectRoles, selectTaxAdvisorClients, selectUserType } from '../app/slices/userDataSlice';
import { useAppSelector } from '../app/hooks';
import * as userRolesConst from "../user-roles-consts";
import { Grid } from '@mui/material';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { selectLocale } from '../app/slices/localsSlice';
import Message from '../sharedComponents/ui/Message';

const SideNavbar = ({ open }: { open: any }) => {

    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('Users');

    const userType = useAppSelector(selectUserType);
    const userRoles = useAppSelector(selectRoles);
    const taxAdvisorClients = useAppSelector(selectTaxAdvisorClients);

    const [isClientsOpen, setIsClientsOpen] = useState(false);
    const [isModulesOpen, setIsModulesOpen] = useState(false);

    const local = useAppSelector(selectLocale)

    const modulesDetails = [
        {
            id: 1,
            nameEn: 'Onboarding',
            nameGr: 'Onboarding',
            sections: []
        },
        {
            id: 1,
            nameEn: 'Personal',
            nameGr: 'Personal',
            sections: [
                {
                    id: 1,
                    nameEn: 'Employees',
                    nameGr: 'Mitarbeiter',
                },
                {
                    id: 2,
                    nameEn: 'Monthly closing',
                    nameGr: 'Monatsabschluss',
                },
                {
                    id: 3,
                    nameEn: 'Evaluations',
                    nameGr: 'Auswertungen',
                }
            ]
        }
    ]

    useEffect(() => {
        if (window.location.pathname.includes('task-details') || window.location.pathname.includes('tasks'))
            setActiveTab('Tasks')
        if (window.location.pathname.includes('users'))
            setActiveTab('Users')
    })

    return (

        <List>
            {/* {(userType === 2 && (!userRoles?.includes(userRolesConst.CLIENT_ADMIN_ID))) ?
                <></> :
                <ListItem disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        onClick={() => {
                            if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/users')
                            else navigate('/users')
                            setActiveTab("Users")
                        }
                        }
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                width: 35,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <img src={activeTab === "Users" ? UserBlueIcon : UserBlackIcon} />
                        </ListItemIcon>
                        <ListItemText primary={'Users'} sx={{ opacity: open ? 1 : 0, color: activeTab === "Users" ? "#5D5FEF" : "black" }} className='neutral-4 labels' />
                    </ListItemButton>
                </ListItem>
            } */}


            {localStorage.getItem('isCMS') === 'true' ?
                <> </>


                :
                <ListItem disablePadding sx={{
                    display: 'block', '& .MuiListItemButton-root': {
                        marginBottom: '10px' // This adds space after each ListItemButton
                    },
                }}>
                    <ListItemButton
                        onClick={() => {
                            if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/users')
                            else navigate('/tasks')
                            setActiveTab("Tasks")
                        }
                        }
                        sx={{
                            minHeight: 28,
                            justifyContent: open ? 'initial' : 'center',
                            backgroundColor: activeTab === "Tasks" ? "#E6E6E8" : "white",
                            marginRight: '20px',
                            marginLeft: '20px',
                            padding: '0px',
                            '&.MuiListItemButton-root': {
                                padding: '0px',
                            },
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                width: 35,
                                mr: open ? 1 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <img src={activeTab === "Tasks" ? taskBlueIcon : taskBlackIcon} alt='icon' />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                fontWeight: 'bold',
                                fontSize: '14px'
                            }}
                            primary={local === 'en' ? 'Tasks' : 'Aufgaben'}
                            sx={{ opacity: open ? 1 : 0, color: activeTab === "Tasks" ? "#5D5FEF" : "black" }} className='neutral-4 labels' />
                    </ListItemButton>

                    <ListItemButton

                        sx={{
                            minHeight: 28,
                            justifyContent: open ? 'initial' : 'center',
                            backgroundColor: activeTab === "News" ? "#E6E6E8" : "white",
                            marginRight: '20px',
                            marginLeft: '20px',
                            padding: '0px',
                            '&.MuiListItemButton-root': {
                                padding: '0px',
                            },
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                width: 35,
                                mr: open ? 1 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <img src={newsBlack} alt='icon' />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                fontWeight: 'bold',
                                fontSize: '14px'
                            }}
                            primary={local === 'en' ? 'News' : 'Nachrichten'}
                            sx={{ opacity: open ? 1 : 0, color: activeTab === "News" ? "#5D5FEF" : "black" }} className='neutral-4 labels' />
                    </ListItemButton>


                    <ListItemButton

                        sx={{
                            minHeight: 28,
                            justifyContent: open ? 'initial' : 'center',
                            backgroundColor: activeTab === "Documents" ? "#E6E6E8" : "white",
                            marginRight: '20px',
                            marginLeft: '20px',
                            padding: '0px',
                            '&.MuiListItemButton-root': {
                                padding: '0px',
                            },
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                width: 35,
                                mr: open ? 1 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <img src={documentsBlack} alt='icon' />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                fontWeight: 'bold',
                                fontSize: '14px'
                            }}
                            primary={local === 'en' ? 'Documents' : 'Dokumente'}
                            sx={{ opacity: open ? 1 : 0, color: activeTab === "Documents" ? "#5D5FEF" : "black" }} className='neutral-4 labels' />
                    </ListItemButton>

                    <Grid item className='greyLine' marginBottom={2} marginTop={1}></Grid>

                    <>
                        <ListItemButton
                            sx={{
                                minHeight: 28,
                                justifyContent: open ? 'initial' : 'center',
                                backgroundColor: activeTab === "Modules" ? "#E6E6E8" : "white",
                                marginRight: '20px',
                                marginLeft: '20px',
                                padding: '0px',
                                '&.MuiListItemButton-root': {
                                    padding: '0px',
                                },
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    width: 35,
                                    mr: open ? 1 : 0,
                                    ml: open ? 0 : 3,
                                    justifyContent: 'center',
                                }}
                            >
                                <img src={modulesBlack} alt='icon' />

                            </ListItemIcon>
                            <Grid container direction='row' alignItems='center' columnGap={0.5}>
                                <Grid item>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontWeight: 'bold',
                                            fontSize: '14px'
                                        }}
                                        primary='Modules'
                                        sx={{ opacity: open ? 1 : 0, color: activeTab === "Modules" ? "#5D5FEF" : "black", fontWeight: 'normal', fontSize: '14px' }}
                                    />
                                </Grid>
                                {open ?
                                    <Grid item
                                        onClick={() => setIsModulesOpen(!isModulesOpen)}>
                                        {isModulesOpen ?
                                            <KeyboardArrowDownIcon style={{ width: '20px', height: '20px', marginTop: '4px', cursor: 'pointer' }} />
                                            :
                                            <KeyboardArrowRightIcon style={{ width: '20px', height: '20px', marginTop: '4px', cursor: 'pointer' }} />
                                        }
                                    </Grid>

                                    :
                                    <></>}
                            </Grid>
                        </ListItemButton>

                        {open && isModulesOpen ?
                            <Grid container direction='column' rowGap={1} paddingLeft={8} marginBottom={isModulesOpen ? 2 : 0}>
                                {modulesDetails?.map((d: any, index: any) => (
                                    <Grid container key={index} className='labels' >
                                        <Grid container direction='row'>
                                            <Grid item>{local === 'en' ? d.nameEn : d.nameGR} </Grid>
                                            {/* {d.sections.length > 0 ?
                                                <Grid item>
                                                    <KeyboardArrowRightIcon style={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                                                </Grid>
                                                :
                                                <></>
                                            } */}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                            :
                            <></>
                        }

                    </>

                    {userType === 1 ?

                        <>
                            <ListItemButton
                                sx={{
                                    minHeight: 28,
                                    justifyContent: open ? 'initial' : 'center',
                                    backgroundColor: activeTab === "News" ? "#E6E6E8" : "white",
                                    marginRight: '20px',
                                    marginLeft: '20px',
                                    padding: '0px',
                                    '&.MuiListItemButton-root': {
                                        padding: '0px',
                                    },
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        width: 35,
                                        mr: open ? 1 : 0,
                                        ml: open ? 0 : 3,
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img src={clientsBlackIcon} alt='icon' />

                                </ListItemIcon>
                                <Grid container direction='row' alignItems='center' columnGap={0.5}>
                                    <Grid item>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                fontWeight: 'bold',
                                                fontSize: '14px'
                                            }}
                                            primary={local === 'en' ? 'Clients' : 'Mandanten'}
                                            sx={{ opacity: open ? 1 : 0, color: activeTab === "News" ? "#5D5FEF" : "black", fontWeight: 'normal', fontSize: '14px' }}
                                        />
                                    </Grid>
                                    {open ?
                                        <Grid item
                                            onClick={() => setIsClientsOpen(!isClientsOpen)}>
                                            {isClientsOpen ?
                                                <KeyboardArrowDownIcon style={{ width: '20px', height: '20px', marginTop: '4px', cursor: 'pointer' }} />
                                                :
                                                <KeyboardArrowRightIcon style={{ width: '20px', height: '20px', marginTop: '4px', cursor: 'pointer' }} />
                                            }
                                        </Grid>

                                        :
                                        <></>}
                                </Grid>
                            </ListItemButton>

                            {open && isClientsOpen ?
                                <Grid container direction='column' style={{ maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden', boxSizing: 'border-box', display: 'block' }} paddingLeft={5}>
                                    {taxAdvisorClients?.map((c: any) => (
                                        <>
                                            {c.name ?
                                                <Grid item width='100%'>
                                                    <ListItemButton
                                                        sx={{
                                                            minHeight: 28,
                                                            marginTop: '10px',
                                                            justifyContent: open ? 'initial' : 'center',
                                                            backgroundColor: activeTab === "News" ? "#E6E6E8" : "white",
                                                            marginRight: '20px',
                                                            marginLeft: '20px',
                                                            padding: '0px',
                                                            fontSize: '14px',
                                                            '&.MuiListItemButton-root': {
                                                                padding: '0px',
                                                            },

                                                        }}
                                                    >

                                                        <ListItemText
                                                            primaryTypographyProps={{
                                                                fontWeight: 'normal',
                                                                fontSize: '14px'
                                                            }}
                                                            primary={c.name} sx={{ opacity: open ? 1 : 0, color: activeTab === "News" ? "#5D5FEF" : "black" }} className='neutral-4 labels' />
                                                    </ListItemButton>
                                                </Grid> : ""}
                                        </>

                                    ))}
                                </Grid>
                                :
                                <></>
                            }

                        </>
                        :
                        <></>
                    }

                </ListItem>
            }


        </List>
    )
}

export default SideNavbar