//REMINDER TYPES
export const TOMORROW_REMINDER_ID = 1;
export const IN_TWO_DAYS_REMINDER_ID = 2;
export const TWO_DAYS_BEFORE_DUE_REMINDER_ID = 3;
export const CUSTOM_REMINDER_ID = 4;

//module types
export const GENERAL_ID = 1;
export const HR_ID = 2;

//task notifications 
export const comment_Id = 1;
export const file_Id = 2;

export const modules = [
    {
        "id": 2,
        "name": "Hr",
        "types": [
            {
                "id": 1,
                "name": "Onboarding"
            },
            {
                "id": 2,
                "name": "Offboarding"
            }
        ]
    },
    {
        "id": 1,
        "name": "General",
        "types": [
            {
                "id": 3,
                "name": "Type3"
            }
        ]
    }
]

export const hrModuleId = 2;
export const onBoardingTypeId = 1;
