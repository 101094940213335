import React, { useEffect, useRef, useState } from 'react';
import { DataGrid, GridColumnMenuProps, GridColDef } from '@mui/x-data-grid';
import { CircularProgress, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import filterIcon from '../../../../assets/images/icons/filterIcon.png';
import activeFilterIcon from '../../../../assets/images/icons/activeFilterIcon.png';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CheckedIcon from '../../../../assets/images/icons/CheckedIcon.png';
import UnCheckedIcon from '../../../../assets/images/icons/UncheckedIcon.png';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CheckIcon from '@mui/icons-material/Check';
import classes from "../../../assets/styles/Table.module.css";
import Message from '../../../../sharedComponents/ui/Message';
import {
    MaterialReactTable,
    useMaterialReactTable,
    type MRT_ColumnDef,
    MRT_Cell,
  } from 'material-react-table';

export interface TableProps {
    rows: [Object];
    loading: boolean;
    switchCompanyStatusCall: (id: string, status:string) => void;
    statusId: number,
    setStatusId: CallableFunction;
    typeId: any;
    setTypeId: any;
    typeName: any;
    setTypeName:any;
    dataLength: any;
    setDataLength: any;
    paginationModel: any;
    setPaginationModel: any;
    clickedType: any;
    setClickedType: CallableFunction;
    clickedTypeName: any;
    setClickedTypeName: any;
    clickedStatus: any;
    setClickedStatus: CallableFunction;
    activeCompanyTooltip: any;
    setActiveCompanyTooltip: CallableFunction;
    loadMoreRows: any;
    setLoadMoreRows: any;
    setOldRows: any;
    setTake: any;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        boxShadow: '0px 2px 4px 0px #0000001F',
        backgroundColor: '#FEFEFF',
        padding: '10px',
        borderRadius: '10px'
    },
}));

// Define the custom column menu component
const CustomColumnMenu = (props: GridColumnMenuProps) => {
    return (
        <div>
            <button onClick={() => console.log('Custom Action')}>Custom Action</button>
        </div>
    );
};

// Define the DataGrid with custom column menu
const Table = (props: TableProps) => {

    const { rows, loading, statusId, setStatusId, switchCompanyStatusCall, typeId,typeName,setTypeName, setTypeId, dataLength, setDataLength, paginationModel, setPaginationModel, clickedType, setClickedType,clickedTypeName, setClickedTypeName, clickedStatus, setClickedStatus, activeCompanyTooltip, setActiveCompanyTooltip,
        loadMoreRows, setLoadMoreRows, setOldRows, setTake
    } = props;

    const planStatuses: string[] = ['Active', 'Inactive']
    const typeStatuses: string[] = ['Client', 'Tax Advisor']

    const [isTypeIconClicked, setIsTypeIconClicked] = useState(false);
    const [isStatusIconClicked, setIsStatusIconClicked] = useState(false);

    const [filterIconHovered, setFilterIconHovered] = useState(false);
    const [filterIconIndex, setFilterIconIndex] = useState('');
    const [filterIndex, setFilterIndex] = useState<number>();

    const [tmpStatusId, setTmpStatusId] = useState();

    const [isOpenStatus, setIsOpenStatus] = useState(false);
    const [isOpenType, setIsOpenType] = useState(false);
    // const [clickedStatus, setClickedStatus]= useState<number>(0);
    // const [clickedType, setClickedType]= useState<number>(0);

    const columnss: GridColDef[] = [

        {
            field: 'name', headerName: 'Company Name', width: 220, headerClassName: 'table-header', sortable: false,

        },
        {
            field: 'email', headerName: 'Company Email', width: 270, headerClassName: 'table-header', sortable: false,
        },
        { field: 'clientCount', headerName: 'Clients Onboarded', width: 200, headerClassName: 'table-header', sortable: false, },
        {
            field: 'type', headerName: 'Type', width: 160, headerClassName: 'table-header',
            // sortable: (activeCompanyTooltip !== 'type' && !isTypeIconClicked),
            sortable: false,
            renderHeader(params: any) {
                return (
                    <ClickAwayListener onClickAway={(event) => {
                        setClickedType(typeId);
                        const target = event.target as HTMLElement;
                        if (
                            // isOpenType &&
                            activeCompanyTooltip === 'type' &&
                            !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
                        ) {
                            // setIsOpenType(false);
                            setActiveCompanyTooltip(null);
                            setIsTypeIconClicked(false);
                        }
                    }}>
                        <Grid item container className='table-header' alignItems='center' fontWeight='normal'
                            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                // setIsOpenType((prev)=>!prev)
                                setActiveCompanyTooltip((prev: any) => (prev === 'type' ? null : 'type'));
                                if (activeCompanyTooltip !== 'type') {
                                    setIsTypeIconClicked(false);
                                }
                                // e.stopPropagation();
                            }}>Type
                            <HtmlTooltip
                                open={activeCompanyTooltip === 'type'}
                                //  onClose={()=>setIsOpenType(false)}
                                disableHoverListener
                                disableFocusListener
                                title={
                                    <React.Fragment>
                                        <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} width='307px' maxHeight='378px' padding={2} display='inline-grid' className='font-14 black normalFontWeight' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                                            {typeStatuses.map((val: string, index: number) => (
                                                <Grid item container alignItems='center' columnGap={1}
                                                    //  className={classes[filterIndex === index || (typeId === 1 && val === 'Tax Advisor') || (typeId === 2 && val === 'Client') ? 'filter-selected' : 'filter-not-selected']}
                                                    style={{
                                                        flexWrap: 'nowrap',
                                                        width: '100%',
                                                        fontSize: '14px',
                                                        marginBottom: '18px'
                                                    }}
                                                    justifyContent='flex-start'
                                                    onMouseOver={(e) => {
                                                        const target = e.target as HTMLElement;
                                                        if ((clickedType !== 1 && val === 'Tax Advisor') || (clickedType !== 2 && val === 'Client')) {
                                                            target.style.cursor = 'pointer';
                                                            setFilterIndex(index);
                                                        }
                                                    }}
                                                    onMouseOut={() => {
                                                        setFilterIndex(-1);
                                                    }}
                                                    onClick={() => {
                                                        // if (val === 'Tax Advisor') setClickedType(1)
                                                        // if (val === 'Client') setClickedType(2)
                                                        if (val === 'Tax Advisor') setClickedType((prevType: any) => (prevType === 1 ? 0 : 1))
                                                        if (val === 'Client') setClickedType((prevType: any) => (prevType === 2 ? 0 : 2))
                                                    }}
                                                >
                                                    <Grid
                                                        className="bordered-box"
                                                        style={{
                                                            borderColor:
                                                                (clickedType === 1 && val === 'Tax Advisor') ||
                                                                    (clickedType === 2 && val === 'Client')
                                                                    ? '#fff'
                                                                    : '#E6E6E8',
                                                        }}
                                                        bgcolor={
                                                            (clickedType === 1 && val === 'Tax Advisor') ||
                                                                (clickedType === 2 && val === 'Client')
                                                                ? '#CCF0EB'
                                                                : '#fff'
                                                        }
                                                    >
                                                        {
                                                            (clickedType === 1 && val === 'Tax Advisor') ||
                                                                (clickedType === 2 && val === 'Client') ?
                                                                // <CheckIcon
                                                                //     fontSize="small"
                                                                //     htmlColor="#00B69B"
                                                                //     style={{ width: '20px', height: '20px' }}
                                                                // />
                                                                <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                                                :
                                                                <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                                        }
                                                    </Grid>
                                                    {/* <Grid item>{val}</Grid> */}
                                                    <Grid item>
                                                        {val === 'Tax Advisor' ? <Message id="filter.ta" className="Field-input labels font-weight-400" /> : <Message id="task.client" className="Field-input labels font-weight-400" />}

                                                    </Grid>
                                                </Grid>
                                            ))}
                                            <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} style={{ cursor: typeId ? 'pointer' : 'unset' }} marginLeft={1.5}
                                                onClick={() => {
                                                    setClickedType(0);
                                                }}><RestartAltIcon fontSize='small' />  <Message id="ta.reset-filter" className="Field-input labels font-weight-400" /></Grid>
                                            <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                                                <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                                    e.stopPropagation();
                                                    // setClickedType(0);
                                                    // setIsOpenType(false);
                                                    setActiveCompanyTooltip(null);
                                                    setIsTypeIconClicked(false);
                                                    setClickedType(typeId);
                                                }}>
                                                    <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                                                </button>
                                                <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                                    setOldRows([]);
                                                    e.stopPropagation();
                                                    setTypeId(clickedType)
                                                    // setIsOpenType(false);
                                                    setActiveCompanyTooltip(null);
                                                    setIsTypeIconClicked(false);
                                                }}>
                                                    <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                                                </button>

                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                }
                            >
                                {/* <ArrowDropDownIcon /> */}
                                <img src={typeId ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                                    e.stopPropagation();
                                    setIsTypeIconClicked(true);
                                    setClickedType(typeId)
                                    setActiveCompanyTooltip((prev: any) => (prev === 'type' ? null : 'type'));
                                }} />
                            </HtmlTooltip >
                        </Grid >
                    </ClickAwayListener>
                )
            },
        },
        {
            field: 'taxAdvisory', headerName: 'Associated Tax Advisor', width: 200, headerClassName: 'table-header',
            sortable: false,
            renderCell: (params) => {
                return (
                    <span> {params.value ? params.value : "N/A"}</span>
                )
            }
        },
        {
            field: 'status',
            headerName: 'Plan Status',
            headerClassName: 'table-header',
            // sortable: (activeCompanyTooltip !== 'status' && !isStatusIconClicked),
            sortable: false,
            renderHeader(params: any) {
                return (
                    <ClickAwayListener onClickAway={(event) => {
                        setClickedStatus(statusId);
                        const target = event.target as HTMLElement;
                        if (
                            // isOpenStatus &&
                            activeCompanyTooltip === 'status' &&
                            !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
                        ) {
                            // setIsOpenStatus(false);
                            setActiveCompanyTooltip(null);
                            setIsStatusIconClicked(false);
                        }
                    }}>
                        <Grid item container className='table-header' alignItems='center' fontWeight='normal'
                            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                // setIsOpenStatus((prev)=>!prev)
                                setActiveCompanyTooltip((prev: any) => (prev === 'status' ? null : 'status'));
                                if (activeCompanyTooltip !== 'status') {
                                    setIsStatusIconClicked(false);
                                }
                                // e.stopPropagation();
                            }}>Plan Status
                            <HtmlTooltip
                                open={activeCompanyTooltip === 'status'}
                                //   onClose={()=>setIsOpenStatus(false)}
                                disableHoverListener
                                disableFocusListener
                                title={
                                    <React.Fragment>
                                        <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} width='307px' maxHeight='378px' padding={2} display='inline-grid' className='font-14 black normalFontWeight' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                                            {planStatuses.map((val: string, index: number) => (
                                                <Grid item container alignItems='center' columnGap={1}
                                                    //  className={classes[filterIndex === index || (statusId === 1 && val === 'Active') || (statusId === 2 && val === 'Inactive') ? 'filter-selected' : 'filter-not-selected']}
                                                    justifyContent='flex-start'
                                                    style={{
                                                        flexWrap: 'nowrap',
                                                        width: '100%',
                                                        fontSize: '14px',
                                                        marginBottom: '18px'
                                                    }}
                                                    onMouseOver={(e) => {
                                                        const target = e.target as HTMLElement;
                                                        if ((clickedStatus !== 1 && val === 'Active') || (clickedStatus !== 2 && val === 'Inactive')) {
                                                            target.style.cursor = 'pointer';
                                                            setFilterIndex(index);
                                                        }
                                                    }}
                                                    onMouseOut={() => {
                                                        setFilterIndex(-1);
                                                    }}
                                                    onClick={() => {
                                                        // if (val === 'Active') setClickedStatus(1)
                                                        // if (val === 'Inactive') setClickedStatus(2)
                                                        if (val === 'Active') setClickedStatus((prevStatus: any) => (prevStatus === 1 ? 0 : 1))
                                                        if (val === 'Inactive') setClickedStatus((prevStatus: any) => (prevStatus === 2 ? 0 : 2))

                                                    }}
                                                >
                                                    <Grid
                                                        className="bordered-box"
                                                        style={{
                                                            borderColor:
                                                                (clickedStatus === 1 && val === 'Active') ||
                                                                    (clickedStatus === 2 && val === 'Inactive')
                                                                    ? '#fff'
                                                                    : '#E6E6E8',
                                                        }}
                                                        bgcolor={
                                                            (clickedStatus === 1 && val === 'Active') ||
                                                                (clickedStatus === 2 && val === 'Inactive')
                                                                ? '#CCF0EB'
                                                                : '#fff'
                                                        }
                                                    >
                                                        {
                                                            (clickedStatus === 1 && val === 'Active') ||
                                                                (clickedStatus === 2 && val === 'Inactive') ?
                                                                // <CheckIcon
                                                                //     fontSize="small"
                                                                //     htmlColor="#00B69B"
                                                                //     style={{ width: '20px', height: '20px' }}
                                                                // />
                                                                <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                                                :
                                                                <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                                        }
                                                    </Grid>
                                                    {/* <Grid item>{val}</Grid> */}
                                                    <Grid item>
                                                        {val === 'Active' ? <Message id="filter.active" className="Field-input labels font-weight-400" /> : <Message id="filter.inactive" className="Field-input labels font-weight-400" />}

                                                    </Grid>
                                                </Grid>
                                            ))}
                                            {/* <Grid item container alignItems='center' columnGap={1} className={classes[statusId === 1 ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                                            onMouseOver={() => {
                                                if (statusId !== 1) {
                                                    setFilterIconIndex('active')
                                                }
                                            }}
                                            onMouseOut={() => {
                                                setFilterIconIndex('')
                                            }}
                                        >
                                            <Grid className='bordered-box' bgcolor={statusId === 1 || filterIconIndex === 'active' ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={statusId === 1 || filterIconIndex === 'active' ? '#00B69B' : '#9E9E9E'} /></Grid>
                                            <Grid item>Active</Grid>
                                        </Grid>
                                        <Grid item container alignItems='center' columnGap={1} className={classes[statusId === 2 ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                                            onMouseOver={() => {
                                                if (statusId !== 2) {
                                                    setFilterIconIndex('in-active')
                                                }
                                            }}
                                            onMouseOut={() => {
                                                setFilterIconIndex('')
                                            }}
                                        >
                                            <Grid className='bordered-box' bgcolor={statusId === 2 || filterIconIndex === 'in-active' ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={statusId === 2 || filterIconIndex === 'in-active' ? '#00B69B' : '#9E9E9E'} /></Grid>
                                            <Grid item>Inactive</Grid>
                                        </Grid> */}
                                            <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} style={{ cursor: clickedStatus ? 'pointer' : 'unset' }} marginLeft={1.5}
                                                onClick={() => {
                                                    setClickedStatus(0);
                                                }}><RestartAltIcon fontSize='small' />  <Message id="ta.reset-filter" className="Field-input labels font-weight-400" /></Grid>
                                            {/* <Grid item container width='80px' marginLeft={7}>
                                            <button className='blueButton' disabled={loading}>Ok</button></Grid> */}
                                            <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                                                <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                                    e.stopPropagation();
                                                    // setClickedStatus(0);
                                                    // setIsOpenStatus(false);
                                                    setActiveCompanyTooltip(null);
                                                    setIsStatusIconClicked(false);
                                                    setClickedStatus(statusId);
                                                }}>
                                                    <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                                                </button>
                                                <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                                    setOldRows([]);
                                                    e.stopPropagation();
                                                    setStatusId(clickedStatus)
                                                    // setIsOpenStatus(false);
                                                    setActiveCompanyTooltip(null);
                                                    setIsStatusIconClicked(false);
                                                }}>
                                                    <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                                                </button>

                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                }
                            >
                                {/* <ArrowDropDownIcon /> */}
                                <img src={statusId ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                                    e.stopPropagation();
                                    setIsStatusIconClicked(true);
                                    setClickedStatus(statusId);
                                    setActiveCompanyTooltip((prev: any) => (prev === 'status' ? null : 'status'));
                                }} />
                            </HtmlTooltip >
                        </Grid >
                    </ClickAwayListener>
                )
            },
            renderCell: (params) => {
                return (
                    <Grid item container direction='row' style={{ marginTop: '-15px', height: '15px' }} columnGap={1.5}>
                        <Grid item className={params.value === 'Active' ? 'active-status' : 'not-active-status'} width='70px'>
                            {params.value === 'Active' ? <Message id="filter.active" className="Field-input labels font-weight-400" /> : <Message id="filter.inactive" className="Field-input labels font-weight-400" />}
                        </Grid>
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Grid item container direction='column' padding={1} rowGap={1} width='150px'>
                                        <Grid item
                                            // className={params.value === 'Active' ? 'selected-item ' : 'not-selected-item '}
                                            className='selected-item'
                                        // style={{ cursor: params.value === 'Active' ? 'unset' : 'pointer' }}

                                        >{params.value === 'Active' ? 'Deactivate ' : 'Activate '} </Grid>
                                        <Grid item container width='80px' marginLeft={7}
                                            onClick={() => {
                                                console.log("test", params.row.id)
                                                // switchCompanyStatusCall(params.row.id)

                                            }}
                                        ><button className='blueButton' disabled={loading}>Ok</button></Grid>
                                    </Grid>
                                </React.Fragment>
                            }
                        >
                            <Grid className='bordered-box'><EditIcon htmlColor='#E6E6E8' fontSize='small' /></Grid>
                        </HtmlTooltip>
                    </Grid>
                );
            },
            width: 170,

        },
        {
            field: 'startDate', headerName: 'Start Date', width: 170, headerClassName: 'table-header', sortable: false,
        },
        {
            field: 'endDate',
            headerName: 'End Date',
            width: 170, headerClassName: 'table-header',
            sortable: false,
        },
        {
            field: 'nameOfPrimaryContact', headerName: 'Name of Primary Contact', width: 220, headerClassName: 'table-header', sortable: false,
        },
        {
            field: 'phoneNbOfPrimaryContact', headerName: 'Phone number of Primary Contact', width: 260, headerClassName: 'table-header', sortable: false,
        },
        {
            field: 'country', headerName: 'Country', width: 160, headerClassName: 'table-header', sortable: false,
        },
        {
            field: 'street', headerName: 'Street', width: 160, headerClassName: 'table-header', sortable: false,
        },
        {
            field: 'houseNb', headerName: 'House Number', width: 160, headerClassName: 'table-header', sortable: false,
        },
        {
            field: 'postalCode', headerName: 'Postal Code', width: 160, headerClassName: 'table-header', sortable: false,
        },

        // {
        //     field: 'actions',
        //     type: 'actions',
        //     width: 80,
        //     getActions: (params) => [
        //     //   <DeleteUserActionItem
        //     //     label="Delete"
        //     //     showInMenu
        //     //     icon={<DeleteIcon />}
        //     //     deleteUser={deleteUser(params.id)}
        //     //     closeMenuOnClick={false}
        //     //   />,
        //     ],
        //   },
    ];

    const handlePaginationModelChange = (model: any) => {
        setPaginationModel(model);
    };

    const gridRef = useRef<any>(null);

    const prevScrollLeft = useRef<number>(0); // Track the previous horizontal scroll position
    const prevScrollTop = useRef<number>(0);

    useEffect(() => {
        console.log("rows check", rows)
        const handleScroll = (event: Event) => {
            const target = event.target as HTMLElement;

            // Check if we're inside the DataGrid's virtual scroller content
            if (target && target.classList.contains("MuiDataGrid-virtualScroller")) {
                const { scrollTop, scrollHeight, clientHeight, scrollLeft } = target;

                const isVerticalScroll = Math.abs(scrollTop - prevScrollTop.current) > Math.abs(scrollLeft - prevScrollLeft.current);


                // Log the scroll position and sizes
                //console.log(`scrollTop: ${scrollTop}, scrollHeight: ${scrollHeight}, clientHeight: ${clientHeight}`);
                // if (scrollLeft === 0 || scrollLeft === scrollWidth - clientHeight) {
                if (isVerticalScroll) {
                    // Determine if the user has reached the bottom
                    if (scrollTop + clientHeight >= scrollHeight - 1) {
                        // console.log("Reached bottom of the scroll!");
                        setLoadMoreRows(true);
                        setOldRows(rows);
                        console.log("oldd rowsss", rows)
                    } else {
                        //console.log("Not at the bottom yet");
                    }
                    prevScrollTop.current = scrollTop;

                }
                prevScrollLeft.current = scrollLeft;

                // }
            }
        };

        const gridContainer = gridRef.current;
        if (gridContainer) {
            // Get the virtual scroller container from the DataGrid
            const virtualScroller = gridContainer.querySelector(
                ".MuiDataGrid-virtualScroller"
            );

            if (virtualScroller) {
                // Attach the scroll event listener
                //console.log("Found virtual scroller container");
                virtualScroller.addEventListener("scroll", handleScroll);
            } else {
                //console.log("MuiDataGrid-virtualScroller not found");
            }
        } else {
            //console.log("Grid container not found");
        }

        return () => {
            const gridContainer = gridRef.current;
            if (gridContainer) {
                const virtualScroller = gridContainer.querySelector(
                    ".MuiDataGrid-virtualScroller"
                );
                if (virtualScroller) {
                    // Remove the scroll event listener when the component unmounts
                    virtualScroller.removeEventListener("scroll", handleScroll);
                }
            }
        };
    });

    const [tableHeight, setTableHeight] = useState(window.innerHeight - 320);

    const handleResize = () => {
        const screenHeight = window.innerHeight;
        setTableHeight(screenHeight - 320);

        if (screenHeight >= 850) {
            console.log("rows", rows)
            setTake(10)
            setLoadMoreRows(true)
        }
    };

    useEffect(() => {
        // Add resize event listener
        window.addEventListener("resize", handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const data: any[] = rows;
    const columns: MRT_ColumnDef<any>[] = [
      {
        accessorKey: 'name', //access nested data with dot notation
        header: 'Company Name',
        minSize: 130,
        muiTableHeadCellProps: {
          sx :{fontWeight: 'normal'}
        },
         Cell: ({ cell }) => (
          <Grid item>
            {cell.getValue<string>()}
          </Grid>
        ),
      },
      {
        accessorKey: 'email', //access nested data with dot notation
        header: 'Company Email',
        muiTableHeadCellProps: {
            sx :{fontWeight: 'normal'}
          },
         Cell: ({ cell }) => (
          <Grid item>
            {cell.getValue<string>()}
          </Grid>
        ),
      },
      {
        accessorKey: 'clientCount', //access nested data with dot notation
        header: 'Clients Onboarded',
        minSize: 130,
        muiTableHeadCellProps: {
            sx :{fontWeight: 'normal'}
          },
         Cell: ({ cell }) => (
          <Grid item>
            {cell.getValue<string>()}
          </Grid>
        ),
      },
      {
        accessorKey: 'type', //access nested data with dot notation
        header: 'Type',
        minSize: 130,
        Header: ({ column }) => (
            <ClickAwayListener onClickAway={(event) => {
                setClickedType(typeId);
                setClickedTypeName(typeName);
                const target = event.target as HTMLElement;
                if (
                    // isOpenType &&
                    activeCompanyTooltip === 'type' &&
                    !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
                ) {
                    // setIsOpenType(false);
                    setActiveCompanyTooltip(null);
                    setIsTypeIconClicked(false);
                }
            }}>
                <Grid item container className='table-header' alignItems='center' fontWeight='normal'
                    onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        // setIsOpenType((prev)=>!prev)
                        setActiveCompanyTooltip((prev: any) => (prev === 'type' ? null : 'type'));
                        if (activeCompanyTooltip !== 'type') {
                            setIsTypeIconClicked(false);
                        }
                        // e.stopPropagation();
                    }}>Type
                    <HtmlTooltip
                        open={activeCompanyTooltip === 'type'}
                        //  onClose={()=>setIsOpenType(false)}
                        disableHoverListener
                        disableFocusListener
                        title={
                            <React.Fragment>
                                <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} width='307px' maxHeight='378px' padding={2} display='inline-grid' className='font-14 black normalFontWeight' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                                    {typeStatuses.map((val: string, index: number) => (
                                        <Grid item container alignItems='center' columnGap={1}
                                            //  className={classes[filterIndex === index || (typeId === 1 && val === 'Tax Advisor') || (typeId === 2 && val === 'Client') ? 'filter-selected' : 'filter-not-selected']}
                                            style={{
                                                flexWrap: 'nowrap',
                                                width: '100%',
                                                fontSize: '14px',
                                                marginBottom: '18px'
                                            }}
                                            justifyContent='flex-start'
                                            onMouseOver={(e) => {
                                                const target = e.target as HTMLElement;
                                                if ((clickedType !== 1 && val === 'Tax Advisor') || (clickedType !== 2 && val === 'Client')) {
                                                    target.style.cursor = 'pointer';
                                                    setFilterIndex(index);
                                                }
                                            }}
                                            onMouseOut={() => {
                                                setFilterIndex(-1);
                                            }}
                                            onClick={() => {
                                                // if (val === 'Tax Advisor') setClickedType(1)
                                                // if (val === 'Client') setClickedType(2)
                                                if (val === 'Tax Advisor') {
                                                    setClickedType((prevType: any) => (prevType === 1 ? 0 : 1))
                                                    setClickedTypeName((prevName:any)=>(prevName === 'TaxAdvisory' ? '' : 'TaxAdvisory'));
                                                }
                                                if (val === 'Client') {setClickedType((prevType: any) => (prevType === 2 ? 0 : 2))
                                                    setClickedTypeName((prevName:any)=>(prevName === 'Client' ? '' : 'Client'));
                                                }
                                            }}
                                        >
                                            <Grid
                                                className="bordered-box"
                                                style={{
                                                    borderColor:
                                                        (clickedType === 1 && val === 'Tax Advisor') ||
                                                            (clickedType === 2 && val === 'Client')
                                                            ? '#fff'
                                                            : '#E6E6E8',
                                                }}
                                                bgcolor={
                                                    (clickedType === 1 && val === 'Tax Advisor') ||
                                                        (clickedType === 2 && val === 'Client')
                                                        ? '#CCF0EB'
                                                        : '#fff'
                                                }
                                            >
                                                {
                                                    (clickedType === 1 && val === 'Tax Advisor') ||
                                                        (clickedType === 2 && val === 'Client') ?
                                                        // <CheckIcon
                                                        //     fontSize="small"
                                                        //     htmlColor="#00B69B"
                                                        //     style={{ width: '20px', height: '20px' }}
                                                        // />
                                                        <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                                        :
                                                        <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                                }
                                            </Grid>
                                            {/* <Grid item>{val}</Grid> */}
                                            <Grid item>
                                                {val === 'Tax Advisor' ? <Message id="filter.ta" className="Field-input labels font-weight-400" /> : <Message id="task.client" className="Field-input labels font-weight-400" />}

                                            </Grid>
                                        </Grid>
                                    ))}
                                    <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} style={{ cursor: typeId ? 'pointer' : 'unset' }} marginLeft={1.5}
                                        onClick={() => {
                                            setClickedType(0);
                                            setClickedTypeName('');
                                        }}><RestartAltIcon fontSize='small' />  <Message id="ta.reset-filter" className="Field-input labels font-weight-400" /></Grid>
                                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                                        <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                            e.stopPropagation();
                                            // setClickedType(0);
                                            // setIsOpenType(false);
                                            setActiveCompanyTooltip(null);
                                            setIsTypeIconClicked(false);
                                            setClickedType(typeId);
                                            setClickedTypeName(typeName);
                                        }}>
                                            <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                                        </button>
                                        <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                            setOldRows([]);
                                            e.stopPropagation();
                                            setTypeId(clickedType)
                                            setTypeName(clickedTypeName)
                                            // setIsOpenType(false);
                                            setActiveCompanyTooltip(null);
                                            setIsTypeIconClicked(false);
                                        }}>
                                            <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                                        </button>

                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                    >
                        {/* <ArrowDropDownIcon /> */}
                        <img src={typeId ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                            e.stopPropagation();
                            setIsTypeIconClicked(true);
                            setClickedType(typeId)
                            setClickedTypeName(typeName);
                            setActiveCompanyTooltip((prev: any) => (prev === 'type' ? null : 'type'));
                        }} />
                    </HtmlTooltip >
                </Grid >
            </ClickAwayListener>
        ),
         Cell: ({ cell }) => (
          <Grid item>
            {cell.getValue<string>()}
          </Grid>
        ),
      },
      {
        accessorKey: 'taxAdvisory', //access nested data with dot notation
        header: 'Associated Tax Advisor',
        minSize: 130,
        muiTableHeadCellProps: {
            sx :{fontWeight: 'normal'}
          },
         Cell: ({ cell }) => (
          <Grid item>
            {cell.getValue<string>()}
          </Grid>
        ),
      },
   
      {
        accessorKey: 'status', //access nested data with dot notation
        header: 'Plan Status',
        minSize: 130,
        Header: ({ column }) => (
            <ClickAwayListener onClickAway={(event) => {
                setClickedStatus(statusId);
                const target = event.target as HTMLElement;
                if (
                    // isOpenStatus &&
                    activeCompanyTooltip === 'status' &&
                    !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
                ) {
                    // setIsOpenStatus(false);
                    setActiveCompanyTooltip(null);
                    setIsStatusIconClicked(false);
                }
            }}>
                <Grid item container className='table-header' alignItems='center' fontWeight='normal'
                    onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        // setIsOpenStatus((prev)=>!prev)
                        setActiveCompanyTooltip((prev: any) => (prev === 'status' ? null : 'status'));
                        if (activeCompanyTooltip !== 'status') {
                            setIsStatusIconClicked(false);
                        }
                        // e.stopPropagation();
                    }}>Plan Status
                    <HtmlTooltip
                        open={activeCompanyTooltip === 'status'}
                        //   onClose={()=>setIsOpenStatus(false)}
                        disableHoverListener
                        disableFocusListener
                        title={
                            <React.Fragment>
                                <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} width='307px' maxHeight='378px' padding={2} display='inline-grid' className='font-14 black normalFontWeight' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                                    {planStatuses.map((val: string, index: number) => (
                                        <Grid item container alignItems='center' columnGap={1}
                                            //  className={classes[filterIndex === index || (statusId === 1 && val === 'Active') || (statusId === 2 && val === 'Inactive') ? 'filter-selected' : 'filter-not-selected']}
                                            justifyContent='flex-start'
                                            style={{
                                                flexWrap: 'nowrap',
                                                width: '100%',
                                                fontSize: '14px',
                                                marginBottom: '18px'
                                            }}
                                            onMouseOver={(e) => {
                                                const target = e.target as HTMLElement;
                                                if ((clickedStatus !== 1 && val === 'Active') || (clickedStatus !== 2 && val === 'Inactive')) {
                                                    target.style.cursor = 'pointer';
                                                    setFilterIndex(index);
                                                }
                                            }}
                                            onMouseOut={() => {
                                                setFilterIndex(-1);
                                            }}
                                            onClick={() => {
                                                // if (val === 'Active') setClickedStatus(1)
                                                // if (val === 'Inactive') setClickedStatus(2)
                                                if (val === 'Active') setClickedStatus((prevStatus: any) => (prevStatus === 1 ? 0 : 1))
                                                if (val === 'Inactive') setClickedStatus((prevStatus: any) => (prevStatus === 2 ? 0 : 2))

                                            }}
                                        >
                                            <Grid
                                                className="bordered-box"
                                                style={{
                                                    borderColor:
                                                        (clickedStatus === 1 && val === 'Active') ||
                                                            (clickedStatus === 2 && val === 'Inactive')
                                                            ? '#fff'
                                                            : '#E6E6E8',
                                                }}
                                                bgcolor={
                                                    (clickedStatus === 1 && val === 'Active') ||
                                                        (clickedStatus === 2 && val === 'Inactive')
                                                        ? '#CCF0EB'
                                                        : '#fff'
                                                }
                                            >
                                                {
                                                    (clickedStatus === 1 && val === 'Active') ||
                                                        (clickedStatus === 2 && val === 'Inactive') ?
                                                        // <CheckIcon
                                                        //     fontSize="small"
                                                        //     htmlColor="#00B69B"
                                                        //     style={{ width: '20px', height: '20px' }}
                                                        // />
                                                        <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                                        :
                                                        <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                                }
                                            </Grid>
                                            {/* <Grid item>{val}</Grid> */}
                                            <Grid item>
                                                {val === 'Active' ? <Message id="filter.active" className="Field-input labels font-weight-400" /> : <Message id="filter.inactive" className="Field-input labels font-weight-400" />}

                                            </Grid>
                                        </Grid>
                                    ))}
                                    {/* <Grid item container alignItems='center' columnGap={1} className={classes[statusId === 1 ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                                    onMouseOver={() => {
                                        if (statusId !== 1) {
                                            setFilterIconIndex('active')
                                        }
                                    }}
                                    onMouseOut={() => {
                                        setFilterIconIndex('')
                                    }}
                                >
                                    <Grid className='bordered-box' bgcolor={statusId === 1 || filterIconIndex === 'active' ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={statusId === 1 || filterIconIndex === 'active' ? '#00B69B' : '#9E9E9E'} /></Grid>
                                    <Grid item>Active</Grid>
                                </Grid>
                                <Grid item container alignItems='center' columnGap={1} className={classes[statusId === 2 ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                                    onMouseOver={() => {
                                        if (statusId !== 2) {
                                            setFilterIconIndex('in-active')
                                        }
                                    }}
                                    onMouseOut={() => {
                                        setFilterIconIndex('')
                                    }}
                                >
                                    <Grid className='bordered-box' bgcolor={statusId === 2 || filterIconIndex === 'in-active' ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={statusId === 2 || filterIconIndex === 'in-active' ? '#00B69B' : '#9E9E9E'} /></Grid>
                                    <Grid item>Inactive</Grid>
                                </Grid> */}
                                    <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} style={{ cursor: clickedStatus ? 'pointer' : 'unset' }} marginLeft={1.5}
                                        onClick={() => {
                                            setClickedStatus(0);
                                        }}><RestartAltIcon fontSize='small' />  <Message id="ta.reset-filter" className="Field-input labels font-weight-400" /></Grid>
                                    {/* <Grid item container width='80px' marginLeft={7}>
                                    <button className='blueButton' disabled={loading}>Ok</button></Grid> */}
                                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                                        <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                            e.stopPropagation();
                                            // setClickedStatus(0);
                                            // setIsOpenStatus(false);
                                            setActiveCompanyTooltip(null);
                                            setIsStatusIconClicked(false);
                                            setClickedStatus(statusId);
                                        }}>
                                            <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                                        </button>
                                        <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                            setOldRows([]);
                                            e.stopPropagation();
                                            setStatusId(clickedStatus)
                                            // setIsOpenStatus(false);
                                            setActiveCompanyTooltip(null);
                                            setIsStatusIconClicked(false);
                                        }}>
                                            <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                                        </button>

                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                    >
                        {/* <ArrowDropDownIcon /> */}
                        <img src={statusId ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                            e.stopPropagation();
                            setIsStatusIconClicked(true);
                            setClickedStatus(statusId);
                            setActiveCompanyTooltip((prev: any) => (prev === 'status' ? null : 'status'));
                        }} />
                    </HtmlTooltip >
                </Grid >
            </ClickAwayListener>
        ),
         Cell: ({ cell }) => (
            <Grid item container direction='row' style={{ marginTop: '-15px', height: '15px' }} columnGap={1.5}>
            <Grid item className={cell.row.original.status === 'Active' ? 'active-status' : 'not-active-status'} width='70px'>
                {cell.row.original.status === 'Active' ? <Message id="filter.active" className="Field-input labels font-weight-400" /> : <Message id="filter.inactive" className="Field-input labels font-weight-400" />}
            </Grid>
            <HtmlTooltip
                title={
                    <React.Fragment>
                        <Grid item container direction='column' padding={1} rowGap={1} width='150px'>
                            <Grid item
                                // className={params.value === 'Active' ? 'selected-item ' : 'not-selected-item '}
                                className='selected-item'
                            // style={{ cursor: params.value === 'Active' ? 'unset' : 'pointer' }}

                            >{cell.row.original.status === 'Active' ? 'Deactivate ' : 'Activate '} </Grid>
                            <Grid item container width='80px' marginLeft={7}
                                onClick={() => {
                                    console.log("test", cell.row.original.id)
                                    switchCompanyStatusCall(cell.row.original.id, cell.row.original.status)

                                }}
                            ><button className='blueButton' disabled={loading}>Ok</button></Grid>
                        </Grid>
                    </React.Fragment>
                }
            >
                <Grid className='bordered-box' sx={{marginTop: '5px'}}><EditIcon htmlColor='#E6E6E8' fontSize='small' /></Grid>
            </HtmlTooltip>
        </Grid>
        ),
      },
      {
        accessorKey: 'startDate', //access nested data with dot notation
        header: 'Start Date',
        minSize: 215,
        muiTableHeadCellProps: {
            sx :{fontWeight: 'normal'}
          },
         Cell: ({ cell }) => (
          <Grid item>
            {cell.getValue<string>()}
          </Grid>
        ),
      },
      {
        accessorKey: 'endDate', //access nested data with dot notation
        header: 'End Date',
        minSize: 130,
        muiTableHeadCellProps: {
            sx :{fontWeight: 'normal'}
          },
         Cell: ({ cell }) => (
          <Grid item>
            {cell.getValue<string>()}
          </Grid>
        ),
      },
      {
        accessorKey: 'primaryContact', //access nested data with dot notation
        header: 'Name Of Primaty Contact',
        minSize: 220,
        muiTableHeadCellProps: {
            sx :{fontWeight: 'normal'}
          },
         Cell: ({ cell }) => (
          <Grid item>
            {cell.getValue<string>()}
          </Grid>
        ),
      },
      {
        accessorKey: 'phoneNumber', //access nested data with dot notation
        header: 'Phone Number of Primary Contact',
        minSize: 260,
        muiTableHeadCellProps: {
            sx :{fontWeight: 'normal'}
          },
         Cell: ({ cell }) => (
          <Grid item>
            {cell.getValue<string>()}
          </Grid>
        ),
      },

      {
        accessorKey: 'country', //access nested data with dot notation
        header: 'Country',
        minSize: 130,
        muiTableHeadCellProps: {
            sx :{fontWeight: 'normal'}
          },
         Cell: ({ cell }) => (
          <Grid item>
            {cell.getValue<string>()}
          </Grid>
        ),
      },
      {
        accessorKey: 'street', //access nested data with dot notation
        header: 'Street',
        minSize: 130,
        muiTableHeadCellProps: {
            sx :{fontWeight: 'normal'}
          },
         Cell: ({ cell }) => (
          <Grid item>
            {cell.getValue<string>()}
          </Grid>
        ),
      },
      {
        accessorKey: 'houseNumber', //access nested data with dot notation
        header: 'House Number',
        minSize: 130,
        muiTableHeadCellProps: {
            sx :{fontWeight: 'normal'}
          },
         Cell: ({ cell }) => (
          <Grid item>
            {cell.getValue<string>()}
          </Grid>
        ),
      },
      {
        accessorKey: 'postalCode', //access nested data with dot notation
        header: 'Postal Code',
        minSize: 130,
        muiTableHeadCellProps: {
            sx :{fontWeight: 'normal'}
          },
         Cell: ({ cell }) => (
          <Grid item>
            {cell.getValue<string>()}
          </Grid>
        ),
      }
    
    
    ]
  
    const table = useMaterialReactTable({
      columns,
      data,
      enableStickyHeader: true,
      enablePagination: false,
      enableBottomToolbar: false,
      enableColumnResizing: true,
      rowCount: 1000,
      // Disable top toolbar features
      enableTopToolbar: false,
      // Or keep toolbar but disable specific features
      enableToolbarInternalActions: false,
      enableColumnFilters: false,
      enableGlobalFilter: false,
      enableFullScreenToggle: false,
      enableColumnActions: false,
      enableSorting: false,
      muiTableContainerProps: {
        sx: {
          // height: '250px', // Set your desired height here
        height: tableHeight,
        }
      },
      // Optional: control what happens when content overflows
      muiTablePaperProps: {
        sx: {
          overflow: 'auto'
        }
      },
    });

    return (
        <div style={{ height: tableHeight, width: '100%' }}>
             {/* <DataGrid 
            //     ref={gridRef}
            //     rows={rows}
            //     columns={columnss}
            //     // pagination
            //     // paginationMode='server'
            //     // paginationModel={paginationModel}
            //     // onPaginationModelChange={handlePaginationModelChange}
            //     // rowCount={dataLength}
            //     // pageSizeOptions={[25, 50, 100]}
            //     // checkboxSelection
            //     disableColumnMenu
            //     sx={{
            //         border: 2,
            //         height: tableHeight,
            //         borderColor: '#FEFEFF',
            //         '& .MuiDataGrid-cell:hover': {
            //             color: 'primary.main',
            //         },
            //         "& .MuiDataGrid-footerContainer": { display: "none" },
            //     }}
            // // slots={{
            // //     columnMenu: CustomColumnMenu, // Use the custom column menu
            // // }}
            // />
            // {loadMoreRows && (
            //     <div
            //         style={{
            //             position: "absolute",
            //             bottom: "40px",
            //             width: "100%",
            //             textAlign: "center",
            //             zIndex: 10,
            //         }}
            //     >
            //         <CircularProgress style={{ color: '#5D5FEF', width: '22px', height: '22px' }} />
            //     </div>
            // )}*/}
            <MaterialReactTable table={table} />
        </div>
    );
};

export default Table;
