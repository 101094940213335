import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress, FormControlLabel, Grid, TextField } from '@mui/material';
import classes from '../../../../../assets/styles/Forms.module.css';
import * as TaskServices from "../../../../../services/task-services.proxy";
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { getDialogLoadingState, getLoadingState, setDialogLoading, setLoading } from '../../../../../app/slices/loadingSlice';
import { getActionCallFrom, getCallStatus, getErrorMsg, setActionCallFrom, setCallStatus, setErrorMsg } from '../../../../../app/slices/apiCallSlice';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import LetterLogo from "../../../../../assets/images/letterLogo.png";
import Message from '../../../../../sharedComponents/ui/Message';
import Textfield from '../../../../../sharedComponents/ui/Textfield';
import { selectLocale } from '../../../../../app/slices/localsSlice';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import 'moment/locale/de';


const updateSurvey = TaskServices.updateSurvey;
const getEmployeeTask = TaskServices.getEmployeeTask;
export interface propsInterface {
    taskId: any,
    onBoardingDetails: any;
    setOnBoardingDetails: any;
}

const Onboarding = (props: propsInterface) => {

    const { taskId, onBoardingDetails,
        setOnBoardingDetails } = props;
    const dispatch = useAppDispatch();

    const dialogLoading = useAppSelector(getDialogLoadingState);
    const errorMsg = useAppSelector(getErrorMsg);
    const loading = useAppSelector(getLoadingState);
    const callStatus = useAppSelector(getCallStatus);
    const local = useAppSelector(selectLocale);
    const actionCallFrom = useAppSelector(getActionCallFrom)

    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => {
        // Updating state with an empty object to trigger a re-render
        updateState({});
    }, []);


    const [employeeTaskDetails, setEmployeeTaskDetails] = useState<any>();
    const [selectedSectionId, setSelectedSectionId] = useState(onBoardingDetails[0].id);
    const [selectedSubSection, setSelectedSubSection] = useState<any>(onBoardingDetails[0].Sections[0]);
    const [selectedSubSectionId, setSelectedSubSectionId] = useState(onBoardingDetails[0].Sections[0].id);
    const [subSections, setSubSections] = useState<any>(onBoardingDetails[0].Sections)

    const updateSurveyCall = () => {
        dispatch(setLoading(true));
        dispatch(setActionCallFrom('update survey'))
        updateSurvey(taskId, onBoardingDetails).then((x) => {
            if (x.ErrorMessage) {
                dispatch(setErrorMsg(x.ErrorMessage));
                dispatch(setCallStatus('Fail'));
                setTimeout(() => {
                    dispatch(setErrorMsg(''));
                    dispatch(setCallStatus(''))
                }, 4500)
            }
            else {
                dispatch(setCallStatus('Pass'));
                setTimeout(() => {
                    dispatch(setErrorMsg(''));
                    dispatch(setCallStatus(''));
                    dispatch(setActionCallFrom(''))
                }, 1500)
            }
            dispatch(setLoading(false));
        })
    }

    const getEmployeeTaskCall = () => {
        dispatch(setDialogLoading(true));
        getEmployeeTask(taskId).then((x) => {
            if (x.ErrorMessage) {
                dispatch(setErrorMsg(x.ErrorMessage));
                // dispatch(setCallStatus('Fail'));
                setTimeout(() => {
                    dispatch(setErrorMsg(''));
                    // dispatch(setCallStatus(''))
                }, 4500)
            }
            else {
                // dispatch(setCallStatus('Pass'));
                setEmployeeTaskDetails(x);
                if (x.survey !== null) setOnBoardingDetails(x.survey)
                setTimeout(() => {
                    dispatch(setErrorMsg(''));
                    // dispatch(setCallStatus(''));
                }, 1500)
            }
            dispatch(setDialogLoading(false));
        })
    }

    useEffect(() => {
        if (!localStorage.getItem('isLoggedIn')) {
            getEmployeeTaskCall();
        }
    }, [])

    return (
        <Grid container direction='column'
            bgcolor={localStorage.getItem('isLoggedIn') ? '' : '#F7F9FF'}
            minHeight={localStorage.getItem('isLoggedIn') ? '' : '100vh'} justifyContent='center'>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={dialogLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container direction='row'>
                {localStorage.getItem('isLoggedIn') ?
                    <></>
                    :
                    <Grid container bgcolor='white' height='100vh' xs={0.5}
                        justifyContent='center'
                        padding={2}
                        boxShadow='4px 0px 10px 0px #0000000F'>
                        <img src={LetterLogo} alt='logo' style={{ width: '30px', height: '42px' }} />

                    </Grid>
                }
                <Grid container xs={!localStorage.getItem('isLoggedIn') ? 11.5 : 12} direction='column'>
                    {!localStorage.getItem('isLoggedIn') ?
                        <Grid item bgcolor='white' width='100vw' height='10vh'></Grid>
                        :
                        <></>
                    }

                    <Grid container padding={!localStorage.getItem('isLoggedIn') ? 2 : 0} direction='column' width={!localStorage.getItem('isLoggedIn') ? '90%' : '100%'} rowGap={3}>

                        {!localStorage.getItem('isLoggedIn') ?
                            <Grid container direction='column' rowGap={1}>
                                <Grid item className='black desktop-header-5' sx={{ marginBottom: '5px' }}>{employeeTaskDetails?.title}</Grid>

                                <Grid container direction='column'>
                                    <Grid item className='black font-14 labels-extra-bold' sx={{ marginBottom: '5px' }}><Message id="task.description" className='black font-14 labels-extra-bold' /></Grid>
                                    <Grid item> {employeeTaskDetails?.description ? employeeTaskDetails?.description : 'N/A'}</Grid>
                                </Grid>

                                <Grid container direction='row'>
                                    <Grid item className='black font-14 labels-extra-bold' xs={1}><Message id="task.client" className="Field-input font-weight-700 font-14" /></Grid>
                                    <Grid item>{employeeTaskDetails?.clientName}</Grid>
                                </Grid>

                                <Grid container direction='row'>
                                    <Grid item className='black font-14 labels-extra-bold' xs={1}><Message id="task.module" className="Field-input font-weight-700 font-14" /></Grid>
                                    <Grid item>{employeeTaskDetails?.module}</Grid>
                                </Grid>
                                <Grid container direction='row'>
                                    <Grid item className='black font-14 labels-extra-bold' xs={1}><Message id="task.type" className="Field-input font-weight-700 font-14" /></Grid>
                                    <Grid item>{employeeTaskDetails?.type}</Grid>
                                </Grid>
                            </Grid>
                            :
                            <></>
                        }
                        <Grid container direction='row' alignItems='center' padding={2} className='whiteContainer' justifyContent='center' columnGap={2} rowGap={1.5}>
                            {onBoardingDetails?.map((section: any, i: any) => (
                                <>
                                    <Grid container direction='column' alignItems='center' rowGap='10px'
                                        style={{ cursor: 'pointer', width: 'auto', minWidth: '120px' }} // width set to auto and a minWidth for layout control
                                        onClick={() => {
                                            setSelectedSectionId(section.id);
                                            const subsections = onBoardingDetails?.find((item: any) => item.id === section.id)?.Sections;
                                            setSubSections(subsections)
                                        }}>
                                        <Grid item key={i} className={classes[selectedSectionId === section.id ? 'selectedCircle' : 'notSelectedCircle']}>{i + 1}</Grid>
                                        <Grid item className={classes[selectedSectionId === section.id ? 'selectedText' : 'notSelectedText']}>
                                            {local === 'en' ? section.nameEn : section.nameGR}
                                        </Grid>

                                    </Grid>

                                    {i !== onBoardingDetails.length - 1 ?
                                        <Grid item className='greyLine' width='50px' height='2px'></Grid>
                                        :
                                        <></>}
                                </>
                            ))}

                        </Grid>

                        <Grid container padding={5} direction='column' className='whiteContainer' rowGap={2.5}>

                            <Grid container direction='row' alignItems='center' justifyContent='center' columnGap={2} rowGap={1.5}>
                                {onBoardingDetails?.find((s: any) => s.id === selectedSectionId)?.Sections?.map((section: any, i: any) => (
                                    <>
                                        <Grid container direction='column' maxWidth='190px' alignItems='center' rowGap='10px'
                                            style={{ cursor: 'pointer', width: 'auto', minWidth: '60px' }} // width set to auto and a minWidth for layout control
                                            onClick={() => {
                                                setSelectedSubSection(section)
                                                setSelectedSubSectionId(section.id)
                                            }}>
                                            <Grid item key={i} className={classes[selectedSubSectionId === section.id ? 'selectedCircle' : 'notSelectedCircle']}>{i + 1}</Grid>
                                            <Grid item className={classes[selectedSubSectionId === section.id ? 'selectedText' : 'notSelectedText']}>
                                                {local === 'en' ? section.nameEn : section.nameGR}
                                            </Grid>

                                        </Grid>

                                        {i !== subSections.length - 1 ?
                                            <Grid item className='greyLine' width='50px' height='2px'></Grid>
                                            :
                                            <></>}
                                    </>
                                ))}

                            </Grid>
                            <Grid container direction='row' justifyContent='space-between' justifySelf='right' alignSelf='flex-end' marginTop={2}>
                                <Grid item className='neutral-5 font-weight-700'>
                                    {local === 'en' ? selectedSubSection.nameEn : selectedSubSection.nameGR}
                                    <br /> <span className='normalFontWeight'>TO BE PROVIDED , Helper text indicating next step </span>
                                </Grid>
                                <Grid item className={classes['inprogress-status']}>In Progress</Grid>
                            </Grid>
                            <Grid item className='greyLine' width='100%' marginTop={3} marginBottom={2}></Grid>


                            <Grid container rowGap={2} justifyContent='space-between'>
                                {selectedSubSection?.fields?.map((field: any, index: any) => (
                                    <>
                                        {
                                            (field.dependantId !== null && selectedSubSection?.fields?.find((f: any) => f.id === field.dependantId)?.value === true) || field.dependantId === null ?

                                                <Grid container direction='column' key={index} rowGap={0.5} xs={field.xs === 6 ? field.xs - 0.1 : field.xs}>
                                                    <Grid container className='labels font-weight-400' columnGap={2} alignItems='center'>
                                                        {local === 'en' ? field.nameEn : field.nameGR}
                                                        {field.type === 'switch' ?
                                                            <FormControlLabel
                                                                checked={field.value}
                                                                control={<IOSSwitch sx={{ m: 1 }}
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                        const isChecked = e.target.checked;

                                                                        // Create a new copy of the details array
                                                                        const newOnBoardingDetails = [...onBoardingDetails];

                                                                        // Find the current section
                                                                        const sectionIndex = newOnBoardingDetails.findIndex(s => s.id === selectedSectionId);
                                                                        if (sectionIndex >= 0) {
                                                                            // Find the current subsection
                                                                            const subSectionIndex = newOnBoardingDetails[sectionIndex].Sections.findIndex(
                                                                                (s: any) => s.id === selectedSubSectionId
                                                                            );

                                                                            if (subSectionIndex >= 0) {
                                                                                // Find the field being modified
                                                                                const fieldIndex = newOnBoardingDetails[sectionIndex].Sections[subSectionIndex].fields.findIndex(
                                                                                    (f: any) => f.id === field.id
                                                                                );

                                                                                if (fieldIndex >= 0) {
                                                                                    // Update the field value
                                                                                    newOnBoardingDetails[sectionIndex].Sections[subSectionIndex].fields[fieldIndex].value = isChecked;

                                                                                    // If turning off, update related fields that are switches
                                                                                    if (!isChecked && field.relatedFieldsIds && field.relatedFieldsIds.length > 0) {
                                                                                        field.relatedFieldsIds.forEach((relatedId: any) => {
                                                                                            const relatedFieldIndex = newOnBoardingDetails[sectionIndex].Sections[subSectionIndex].fields.findIndex(
                                                                                                (f: any) => f.id === relatedId
                                                                                            );

                                                                                            if (relatedFieldIndex >= 0) {
                                                                                                // Only set to false if the related field is also a switch
                                                                                                if (newOnBoardingDetails[sectionIndex].Sections[subSectionIndex].fields[relatedFieldIndex].type === 'switch') {
                                                                                                    newOnBoardingDetails[sectionIndex].Sections[subSectionIndex].fields[relatedFieldIndex].value = false;
                                                                                                }
                                                                                            }
                                                                                        });
                                                                                    }

                                                                                    // Set the state with the updated copy
                                                                                    setOnBoardingDetails(newOnBoardingDetails);
                                                                                }
                                                                            }
                                                                        }
                                                                    }}
                                                                />}
                                                                label=""
                                                            />
                                                            :
                                                            <></>
                                                        }
                                                    </Grid>
                                                    <Grid item>
                                                        {field.type === 'textfield' ?
                                                            <TextField
                                                                fullWidth
                                                                label={!field.value ? local === 'en' ? field.nameEn : field.nameGR : ''}
                                                                value={field.value}
                                                                onChange={(e) => {
                                                                    field.value = e.target.value;
                                                                    forceUpdate();
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: false,
                                                                }}
                                                                sx={{
                                                                    '& label.Mui-focused': {
                                                                        color: '#C3C2C7',
                                                                    },
                                                                    '& .MuiInputLabel-root': {
                                                                        color: '#C3C2C7',
                                                                        marginTop: '-7px',
                                                                        fontSize: '16px'
                                                                    },
                                                                    '& .MuiInputLabel-root.Mui-focused': {
                                                                        color: '#C3C2C7',
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#B2BAC2',
                                                                    },
                                                                    '& .MuiOutlinedInput-root': {
                                                                        '& fieldset': {
                                                                            borderColor: '#C3C2C7',
                                                                        },
                                                                        '&:hover fieldset': {
                                                                            borderColor: '#B2BAC2',
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                            borderColor: '#6F7E8C',
                                                                        },
                                                                    },
                                                                }}
                                                                InputProps={{ className: 'fieldDesign' }}
                                                            />
                                                            : field.type === 'dateTextfield' ?
                                                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
                                                                    <DatePicker
                                                                        // shouldDisableDate={(date) =>
                                                                        //     date.isBefore(moment(), 'day')
                                                                        // }
                                                                        value={field.value ? moment(field.value, 'M/D/YYYY') : null}
                                                                        onChange={(e) => {
                                                                            console.log("e.target.", e)
                                                                            if (e && e.isValid()) {
                                                                                field.value = moment(e).format('M/D/YYYY');
                                                                                forceUpdate();

                                                                            }
                                                                        }}
                                                                        format="DD.MM.YYYY"
                                                                        slotProps={{
                                                                            textField: {
                                                                                fullWidth: true,
                                                                                sx: {
                                                                                    // '& label.Mui-focused': {
                                                                                    //     color: 'white',
                                                                                    // },
                                                                                    // '& .MuiInput-underline:after': {
                                                                                    //     borderBottomColor: 'white',
                                                                                    // },
                                                                                    '& .MuiOutlinedInput-root': {
                                                                                        '& fieldset': {
                                                                                            borderRadius: '8px'
                                                                                        },
                                                                                        // '&:hover fieldset': {
                                                                                        //     borderColor: 'white',
                                                                                        // },
                                                                                        // '&.Mui-focused fieldset': {
                                                                                        //     borderColor: 'white',
                                                                                        // },
                                                                                    },
                                                                                },
                                                                                size: "small",
                                                                                variant: "outlined"
                                                                            },
                                                                        }}
                                                                    />
                                                                </LocalizationProvider>
                                                                :
                                                                <></>
                                                        }


                                                    </Grid>




                                                </Grid>
                                                :
                                                <></>
                                        }
                                    </>
                                ))}
                            </Grid>

                            <Grid item container direction="row" columnSpacing={2} justifyContent='flex-end'>
                                <Grid item width='110px'>
                                    <button
                                        className='greyButton'
                                        disabled={selectedSubSectionId <= 1}
                                        onClick={() => {
                                            setSelectedSubSectionId(selectedSubSectionId - 1);
                                            const tmp = onBoardingDetails?.find((s: any) => s.id === selectedSectionId)?.Sections?.find((sub: any) => sub.id === selectedSubSectionId - 1)
                                            setSelectedSubSection(tmp)
                                        }
                                        }>
                                        Back
                                    </button>
                                </Grid>
                                <Grid item width='110px'>
                                    <button
                                        className={callStatus === 'Pass' && actionCallFrom === 'update survey' ? 'greenButton' : callStatus === 'Fail' && actionCallFrom === 'update survey' ? 'redButton' : 'blueButton'}
                                        disabled={(loading || callStatus === 'Pass') && actionCallFrom === 'update survey'}
                                        onClick={() => {
                                            if (selectedSubSectionId === subSections[subSections.length - 1].id) updateSurveyCall()
                                            else {
                                                setSelectedSubSectionId(selectedSubSectionId + 1);
                                                const tmp = onBoardingDetails?.find((s: any) => s.id === selectedSectionId)?.Sections?.find((sub: any) => sub.id === selectedSubSectionId + 1)
                                                setSelectedSubSection(tmp)
                                            }
                                        }}>
                                        {loading && actionCallFrom === 'update survey' ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                                            : callStatus === 'Pass' && actionCallFrom === 'update survey' ? <CheckCircleOutlinedIcon />
                                                : callStatus === 'Fail' && actionCallFrom === 'update survey' ? "Failed"
                                                    :
                                                    selectedSubSectionId === subSections[subSections.length - 1].id ? "Save" : "Next"
                                        }</button>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>
            </Grid>



        </Grid >

    )
}

export default Onboarding

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 60, // Set the width of the entire switch
    height: 30, // Set the height of the entire switch
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(30px)', // Adjust for new width
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#5D5FEF', // Blue color when checked
                opacity: 1,
                border: 0,
                ...theme.applyStyles('dark', {
                    backgroundColor: '#5D5FEF', // Darker blue in dark mode
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
            ...theme.applyStyles('dark', {
                color: theme.palette.grey[600],
            }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
            ...theme.applyStyles('dark', {
                opacity: 0.3,
            }),
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 25, // Set the width of the thumb
        height: 25, // Set the height of the thumb
    },
    '& .MuiSwitch-track': {
        borderRadius: 30 / 2, // Half of the new height for rounded corners
        backgroundColor: '#C3C2C7',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        ...theme.applyStyles('dark', {
            backgroundColor: '#39393D',
        }),
    },
}));
