import React, { useState, useMemo, useEffect } from 'react';
import { Backdrop, CircularProgress, Grid } from '@mui/material';
import { useMsal } from "@azure/msal-react";
import * as authConfig from "../../authConfig";
import classes from "../../assets/styles/Login.module.css";
import microsoftIcon from "../../assets/images/icons/microsoft.png";
import googleIcon from "../../assets/images/icons/google.png";
import datevIcon from "../../assets/images/icons/datev.png";
import * as authServices from "../../services/auth-services.proxy";
import * as datevServices from "../../services/datev-services.proxy";
import { errorActions, getErrorMsg } from '../../app/slices/apiCallSlice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { setIsSSO, setIsLoggedIn, setIsOtpEnabled, selectIsOtpEnabled, setIsOtpVerified, setIsCMS, setIsSubscribed, setUserType } from '../../app/slices/userDataSlice';
import { setLoading, getLoadingState, getDialogLoadingState, setDialogLoading } from '../../app/slices/loadingSlice';
import Message from '../../sharedComponents/ui/Message';
import DatevSignUp from './DatevSignUp';

const googleLogin = authServices.googleLogin;
const microsoftLogin = authServices.microsoftLogin;
const datevLogin = authServices.datevLogin;
const getDatevAuthCodeUrl = datevServices.getDatevAuthCodeUrl;

const SSOLogin = ({ loginFormState, setLoginFormState }: { loginFormState: string, setLoginFormState: any }) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const tenantId = query.get('tenant');
  const code = query.get("code");
  const userType = query.get("type");

  const errorMsg = useAppSelector(getErrorMsg);

  const loading = useAppSelector(getLoadingState);
  const dialogLoading = useAppSelector(getDialogLoadingState);

  useEffect(() => {
    if (code) loginWithDatev();

  }, [code])

  const loginWithGoogle = (googleToken: any, refreshToken: string) => {
    dispatch(setDialogLoading(true))
    googleLogin(googleToken, tenantId ? tenantId : "", refreshToken).then((x) => {
      if (x.ErrorMessage) {
        dispatch(errorActions.setErrorMsg(x.ErrorMessage));
        setLoginFormState('SSO-fail');
        localStorage.setItem('loginFormState', 'SSO-fail')
        // setTimeout(() => {
        //   setLoginFormState('default');
        //   localStorage.setItem('loginFormState', 'default')
        // }, 4000)
      }
      else {

        //setLoginFormState('verify-user');
        //localStorage.setItem('loginFormState','verify-user')
        // dispatch(userActions.updateToken(x.accessToken));
        
        localStorage.setItem("loginTime", Date.now().toString());

        localStorage.setItem('ssoType', 'google');

        localStorage.setItem("isLoggedIn", 'true');
        dispatch(setIsLoggedIn(true));

        localStorage.setItem("isSSO", 'true');
        dispatch(setIsSSO(true));

        localStorage.setItem("isCMS", x.isCMS);
        dispatch(setIsCMS(x.isCMS));

        localStorage.setItem("isOtpEnabled", x.otpEnabled);
        dispatch(setIsOtpEnabled(x.otpEnabled));

        localStorage.setItem("isOtpVerified", 'false');
        dispatch(setIsOtpVerified(false));

        localStorage.setItem("userType", x.typeId);
        dispatch(setUserType(x.typeId));

        navigate('/2fa')

      }
      dispatch(setDialogLoading(false))
    })
  }

  const loginWithMicrosoft = (microsoftToken: any) => {
    dispatch(setDialogLoading(true))
    microsoftLogin(microsoftToken, tenantId ? tenantId : "").then((x) => {
      if (x.ErrorMessage) {
        dispatch(errorActions.setErrorMsg(x.ErrorMessage));
        setLoginFormState('SSO-fail');
        localStorage.setItem('loginFormState', 'SSO-fail')
      }
      else {
        //setLoginFormState('verify-user');
        //localStorage.setItem('loginFormState', 'verify-user')

        //dispatch(userActions.updateToken(x.accessToken));

        // localStorage.setItem("isLoggedIn", 'true');
        // dispatch(setIsLoggedIn(true));
        // localStorage.setItem("isSSO", 'true');
        // dispatch(setIsSSO(true));
        // navigate('/dashboard')
        
        localStorage.setItem("loginTime", Date.now().toString());

        localStorage.setItem('ssoType', 'microsoft');

        localStorage.setItem("isLoggedIn", 'true');
        dispatch(setIsLoggedIn(true));

        localStorage.setItem("isCMS", x.isCMS);
        dispatch(setIsCMS(x.isCMS));

        localStorage.setItem("isSSO", 'true');
        dispatch(setIsSSO(true));

        localStorage.setItem("isOtpEnabled", x.otpEnabled);
        dispatch(setIsOtpEnabled(x.otpEnabled));

        localStorage.setItem("isOtpVerified", 'false');
        dispatch(setIsOtpVerified(false));

        localStorage.setItem("userType", x.typeId);
        dispatch(setUserType(x.typeId));

        navigate('/2fa')
      }
      dispatch(setDialogLoading(false))
    })
  }

  const loginWithDatev = () => {
    if (code) {
      console.log("code",code);
      dispatch(setDialogLoading(true))
      datevLogin(code, localStorage.getItem('tenantId') ? localStorage.getItem('tenantId') : null).then((x) => {
        if (x.ErrorMessage) {
          dispatch(errorActions.setErrorMsg(x.ErrorMessage));
          setLoginFormState('SSO-fail');
          localStorage.setItem('loginFormState', 'SSO-fail')
        }
        else {

          localStorage.setItem("loginTime", Date.now().toString());

          localStorage.setItem("isLoggedIn", 'true');
          dispatch(setIsLoggedIn(true));

          localStorage.setItem("isSSO", 'true');
          dispatch(setIsSSO(true));

          localStorage.setItem("isCMS", x.isCMS);
          dispatch(setIsCMS(x.isCMS));

          localStorage.setItem("isOtpEnabled", x.otpEnabled);
          dispatch(setIsOtpEnabled(x.otpEnabled));

          localStorage.setItem("isOtpVerified", 'false');
          dispatch(setIsOtpVerified(false));

          localStorage.setItem("isSubscribed", x.isActivated);
          dispatch(setIsSubscribed(x.isActivated));

          localStorage.setItem("userType", x.typeId);
          dispatch(setUserType(x.typeId));

          navigate('/2fa');

        }
        dispatch(setDialogLoading(false))
      })
    }


  }

  const { instance } = useMsal();
  const handleMicrosoftLogin = () => {
    instance.loginPopup(authConfig.loginRequest).then((x) => {
      console.log("microsoft response", x)
      let token = x.idToken;
      if (token) {
        loginWithMicrosoft(token)
      }
      else {
        setLoginFormState('SSO-fail');
        localStorage.setItem('loginFormState', 'SSO-fail')
      }

    })
      .catch((e) => {
        setLoginFormState('SSO-fail');
        localStorage.setItem('loginFormState', 'SSO-fail')
      });
  };

  const handleGoogleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async codeResponse => {
      // Extract authorization code from the response
      const authCode = codeResponse.code;
      console.log("code", authCode)
      // Exchange authorization code for tokens
      try {
        const tokenResponse = await exchangeCodeForTokens(authCode);
        console.log('token response', tokenResponse); // This should contain the access token and refresh token
        loginWithGoogle(tokenResponse.id_token, tokenResponse.refresh_token)
      } catch (error) {
        console.error('Error exchanging code for tokens:', error);
      }
    },
    onError: errorResponse => console.log(errorResponse),
  });

  const handleDatevLogin = () => {
    dispatch(setDialogLoading(true))
    getDatevAuthCodeUrl().then((x) => {
      if (x.ErrorMessage) {
        dispatch(errorActions.setErrorMsg(x.ErrorMessage));
      }
      else {
        window.location.href = x;

      }
      dispatch(setDialogLoading(false))
    })
  }

  const exchangeCodeForTokens = async (authCode: string) => {
    const clientId = `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`;
    const clientSecret = `${process.env.REACT_APP_GOOGLE_CLIENT_SECRET}`;
    const redirectUri = `${process.env.REACT_APP_GOOGLE_REDIRECT_URI}`;
    const tokenEndpoint = 'https://oauth2.googleapis.com/token';

    try {
      const response = await fetch(tokenEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          code: authCode,
          client_id: clientId,
          client_secret: clientSecret,
          redirect_uri: redirectUri,
          grant_type: 'authorization_code',
        }),
      });

      if (!response.ok) {
        const errorDetails = await response.text();
        throw new Error(`Error ${response.status}: ${errorDetails}`);
      }

      const data = await response.json();
      return data; // This should contain the access token, refresh token, and other details

    } catch (error) {
      console.error('Error exchanging code for tokens:', error);
      throw error;
    }
  };

  const handleDATEVLogin = () => {
    let loginUri = `${process.env.REACT_APP_OAUTH_DATEV_LoginUri}`;
    let redirectUri = `${process.env.REACT_APP_OAUTH_DATEV_RedirectUri}`;

    let clientId = `${process.env.REACT_APP_OAUTH_DATEV_ClientId}`;
    let clientSecret = `${process.env.REACT_APP_OAUTH_DATEV_ClientSecret}`;

    let form = document.createElement("form");
    form.setAttribute('method', "GET");
    form.setAttribute('action', loginUri);

    let params: any = {
      'client_id': clientId,
      //'client_secret': clientSecret,
      'redirect_uri': redirectUri,

      'response_type': "code",
      //'access_type': "offline",

      'scope': "openid profile",

      'state': `D001`,
    };

    for (let p in params) {
      let input = document.createElement("input");
      input.setAttribute('type', "hidden");
      input.setAttribute('name', p);
      input.setAttribute('value', params[p]);
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
  };

  return (
    <Grid item container direction="column" alignItems='center' rowGap={1.2}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={dialogLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {userType && parseInt(userType) === 1 ?
        <DatevSignUp handleDatevLogin={handleDatevLogin} />
        :
        <>
          {!(tenantId && userType) && (
            <>
              <Grid item>
                <Grid item style={{ marginBottom: '10px' }}>
                  <Message id='login.are-you-a-ta' className='Field-input font-weight-400 font-14' />
                </Grid>
                <button className={classes['loginButton']} onClick={() => handleDatevLogin()} disabled={userType && parseInt(userType) === 2 ? true : false}>
                  <Grid item container textAlign='start' alignItems='center'
                  >
                    <img src={datevIcon} alt='icon' style={{ paddingRight: '77px', paddingLeft: '21px' }} width='18px' height='18px' />
                    <Message
                      id="login.sign-in-with-datev"
                    />
                  </Grid>
                </button>
              </Grid>
              <Grid item container
              style={{ marginLeft: '-10px' }}
              >
                <Message id='login.are-you-a-client' className='Field-input font-weight-400 font-14' />
              </Grid>
            </>
          )
          }

          <Grid item container justifyContent='center' style={{
            border: (userType && parseInt(userType) === 2 && tenantId) ? 'none' : '1px solid #E6E6E8',

            borderBottom: 'none', paddingTop: '13px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', minWidth: '380px'
          }}>
            <Grid item>
              <button className={classes['loginButton']} style={{ marginBottom: '10px', width: (userType && parseInt(userType) === 2 && tenantId) ?'380px' :'344px' }} onClick={() => handleGoogleLogin()} disabled={userType && parseInt(userType) === 1 ? true : false}>
                <Grid item container textAlign='start' alignItems='center'
                >
                  <img src={googleIcon} alt='icon' style={{ paddingRight: '77px', paddingLeft: '21px' }} width='18px' height='18px' />
                  <span style={{marginLeft:  (userType && parseInt(userType) === 2 && tenantId) ?'0px' :'-16px'}}>
                  <Message
                    id="login.sign-in-with-google"
                  />
                  </span>
                </Grid>
              </button>
            </Grid>
            <Grid item>
              <button className={classes['loginButton']} style={{ width: (userType && parseInt(userType) === 2 && tenantId) ?'380px' :'344px' }} onClick={() => handleMicrosoftLogin()} disabled={userType && parseInt(userType) === 1 ? true : false}>
                <Grid item container textAlign='start' alignItems='center'
                >
                  <img src={microsoftIcon} alt='icon' style={{ paddingRight: '77px', paddingLeft: '21px' }} width='18px' height='18px' />
                  <span style={{marginLeft:(userType && parseInt(userType) === 2 && tenantId) ?'0px' :'-16px'}}>
                  <Message
                    id="login.sign-in-with-microsoft"
                  />
                  </span>
                </Grid>
              </button>
            </Grid>
          </Grid>

          {errorMsg ?
            <Grid item className='errorText'>{errorMsg}</Grid>
            :
            <></>}
        </>


      }

    </Grid>

  )
}

export default SSOLogin